<template>
  <!-- client logo section -->
  <div class="client-logos">
    <div class="container">
      <h2>Brands We've Brought Into the Future</h2>
      <div class="featured-logos expanded">
        <!-- TO-DO: png's or svg's here? -->
        <!-- TO-DO: make json data file for logos and populate page using v:for -->
        <!-- TO-DO: should we use <pitcure> here for responsive? -->
        <!-- NOTE: the logos will be the same size regardless of device -->
        <div class="client-logo">
          <img src="../../assets/images/snap-logo.png" width="50" height="50" alt="SnapChat Logo">
        </div>

        <div id ="niantic" class="client-logo">
          <img src="../../assets/images/niantic-logo.png" width="100" height="100" alt="Niantic Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/qualcomm-logo.png" width="150" height="30" alt="Qualcomm Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/hbo-logo.png" width="120" height="50" alt="HBO Logo">
        </div>


        <div class="client-logo">
          <img src="../../assets/images/espn-logo.png" width="135" height="34" alt="Marvel Logo">
        </div><div class="client-logo">
          <img src="../../assets/images/fifa-logo.png" width="120" height="40" alt="Marvel Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/marvel-logo.png" width="125" height="50" alt="Marvel Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/sxsw-logo.png" width="150" height="35" alt="SxSW Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/google-logo.png" width="150" height="50" alt="Google Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/meta-logo.png" width="150" height="30" alt="Meta Logo">
        </div>
        <!-- these logos are initially hidden ADD class .more to logos to hide them-->
        <div class="client-logo">
          <img src="../../assets/images/pepsi-logo.png" width="150" height="50" alt="Pespi Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/warner-logo.png" width="41" height="50" alt="Warner Bros Logo">
        </div>
        <!-- <div class="client-logo">
          <img src="../../assets/images/snap-logo.png" width="50" height="50" alt="SnapChat Logo">
        </div> -->
        <div class="client-logo">
          <img src="../../assets/images/pez-logo.png" width="150" height="43" alt="Pez Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/zenni-logo.png" width="150" height="31" alt="Zenni Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/asmodee-logo.png" width="150" height="24" alt="Asmodee Logo">
        </div>
        <!-- <div class="client-logo">
          <img src="../../assets/images/chapstick-logo.png" width="150" height="32" alt="Chapstick Logo">
        </div> -->
        <div class="client-logo">
          <img src="../../assets/images/amazon-logo.png" width="150" height="45" alt="Amazon Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/disney-logo.png" width="118" height="50" alt="Disney Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/sony-logo.png" width="150" height="26" alt="Sony Logo">
        </div>
        <div class="client-logo">
          <img src="../../assets/images/msp-logo.png" width="88" height="76" alt="Sony Logo" class="autoHeight">
        </div>
      </div>
      <!-- <button @click="toggleMoreLogos">{{ logoButtonText }}</button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Clients',
  data(){
    return {
      showMoreLogos: false,
      logoButtonText: "See More"
    }
  },
  methods: {
    toggleMoreLogos() {
      
      let featuredLogos = document.querySelector(".featured-logos")
      let moreLogos = document.querySelectorAll(".more")

      if(!this.showMoreLogos){
        // show more logos
        featuredLogos.classList.add("expanded")
        moreLogos.forEach((logoElement) => {
          logoElement.classList.add("active")
          setTimeout(() => {
            logoElement.classList.add("visible")
            this.logoButtonText = "See Less"
          }, 1)
        })
      } else {
        // hide more logos
        moreLogos.forEach((logoElement) => {
          featuredLogos.classList.remove("expanded")
          logoElement.classList.remove("visible")
          setTimeout(() => {
            logoElement.classList.remove("active")
            this.logoButtonText = "See More"
          }, 500)
        })
      }

      this.showMoreLogos = !this.showMoreLogos
    
    }
  },
  created() {

  }
}
</script>

<style scoped>
  /* Home Page: Client Logo Section
  ----------------------------- */
  .client-logos {
    padding: 0 30px;
    min-height: 400px;
    background: linear-gradient(0deg,#b54656,#ff6b81);
  }
  .client-logos .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0 80px;
    max-width: 1100px;
    margin: 0 auto;
  }
  .client-logos h2 {
    max-width: 720px;
    margin-top: 0;
    margin-bottom: 60px;
    text-align: center;
    font-size: 2.5rem;
    color: #fff;
  }
  .client-logos .featured-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* max-width: 720px; */
    max-width: 800px;
    /* column-gap: 40px; */
    column-gap: 55px;
    overflow: hidden;
    height: auto;
    max-height: 100px;
    transition: max-height 0.5s ease 0s;
  }
  .client-logos .featured-logos.expanded {
    max-height: 1410px;
  }
  .client-logos .more {
    display: none;
    opacity: 0;
    transition: all 0.5s ease 0s,
  }
  .client-logos .more.active {
    display: flex;
  }
  .client-logos .more.visible {
    opacity: 1;
  }
  .client-logos #niantic  img {
    max-height: 100px;
  }
  .client-logos img {
    max-height: 50px;
  }
  .client-logo img.autoHeight {
    max-height: none;
  }
  .client-logos button {
    padding: 12px 16px;
    border: 3px solid transparent;
    border-radius: 10px;
    margin-top: 50px;
    background: #ffc300;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #1e90ff;
    cursor: pointer;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s, font-size 0.1s cubic-bezier(.68,-.25,.265,1.25);
  }
  .client-logos button:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 32px;
    transform: translateY(-3px);
  }
  .client-logos button:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  .client-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    padding: 20px 0;
    width: 150px;
  }
  .client-logo img {
    max-width: 100%;
  }
  @media screen and (max-width: 1000px) {
    .client-logos .featured-logos {
      /* max-width: 690px; */
      /* column-gap: 30px; */
    }
  }
  @media screen and (max-width: 900px) {
    .client-logos .featured-logos {
      /* max-width: 660px; */
      /* column-gap: 20px; */
    }
  }
  @media screen and (max-width: 700px) {
    .client-logos {
      padding: 0 15px;
    }
    .client-logos h2 {
      max-width: 400px;
    }
    .client-logos .featured-logos {
      /* max-height: 200px; */
      max-height: 250px;
      /* max-width: 400px; */
      /* column-gap: 100px; */
    }
  }
  @media screen and (max-width: 600px) {
    /* .client-logos {
      padding: 0 15px;
    } */
    .client-logos .featured-logos {
      /* max-width: 350px; */
      /* column-gap: 50px; */
    }
  }
  @media screen and (max-width: 500px) {
    /* .client-logos {
      padding: 0 15px;
    } */
    .client-logos h2 {
      font-size: 2.25rem;
    }
    .client-logos .featured-logos {
      /* max-width: 320px; */
      /* column-gap: 20px; */
    }
    .client-logos img {
      /* margin-bottom: 20px; */
    }
  }
  @media screen and (max-width: 400px) {
    .client-logos .featured-logos {
      /* max-width: 320px; */
      column-gap: 45px;
    }
    /* .client-logo {
      width: 130px;
    } */
    /* .client-logos img {
      margin-bottom: 10px;
    } */
  }
  @media screen and (max-width: 320px) {
    .client-logos .container {
      padding: 30px 0 40px;
    }
    .client-logos h2 {
      margin-bottom: 40px;
    }
    /* .client-logos img {
      margin-bottom: 10px;
    }  */
  }
</style>