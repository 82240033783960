<template>
  <footer>
    <div class="container">
      <div class="links">
        <div class="email">
          <a href="mailto:alex@mousepack.com">alex@mousepack.com</a>
        </div>
        <div class="social-links">

          <!-- instagram logo and link -->
          <a href="https://www.instagram.com/mousepack.co" target="_blank" rel="noopener noreferrer">

            <svg title="Go To MousePack Instagram Page" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
              <g>
                <path fill="#ffffff" class="socialLogo" d="M16,2.9c4.3,0,4.8,0,6.5,0.1c1.6,0.1,2.4,0.3,3,0.6c0.7,0.3,1.3,0.6,1.8,1.2c0.6,0.6,0.9,1.1,1.2,1.8
                c0.2,0.6,0.5,1.4,0.6,3c0.1,1.7,0.1,2.2,0.1,6.5s0,4.8-0.1,6.5c-0.1,1.6-0.3,2.4-0.6,3c-0.3,0.7-0.6,1.3-1.2,1.8
                c-0.6,0.6-1.1,0.9-1.8,1.2c-0.6,0.2-1.4,0.5-3,0.6c-1.7,0.1-2.2,0.1-6.5,0.1s-4.8,0-6.5-0.1c-1.6-0.1-2.4-0.3-3-0.6
                c-0.7-0.3-1.3-0.6-1.8-1.2c-0.6-0.6-0.9-1.1-1.2-1.8C3.3,24.8,3,24,3,22.4c-0.1-1.7-0.1-2.2-0.1-6.5s0-4.8,0.1-6.5
                C3,8,3.3,7.1,3.5,6.6c0.3-0.7,0.6-1.3,1.2-1.8s1.1-0.9,1.8-1.2C7.1,3.3,8,3,9.5,3C11.2,2.9,11.7,2.9,16,2.9 M16,0
                c-4.3,0-4.9,0-6.6,0.1C7.7,0.2,6.5,0.4,5.5,0.8c-1.1,0.4-1.9,1-2.8,1.8S1.2,4.5,0.8,5.5c-0.4,1-0.7,2.2-0.7,3.9C0,11.1,0,11.6,0,16
                s0,4.9,0.1,6.6c0.1,1.7,0.3,2.9,0.7,3.9c0.4,1.1,1,1.9,1.8,2.8c0.9,0.9,1.8,1.4,2.8,1.8c1,0.4,2.2,0.7,3.9,0.7
                c1.7,0.1,2.2,0.1,6.6,0.1s4.9,0,6.6-0.1c1.7-0.1,2.9-0.3,3.9-0.7c1.1-0.4,1.9-1,2.8-1.8c0.9-0.9,1.4-1.8,1.8-2.8
                c0.4-1,0.7-2.2,0.7-3.9c0.1-1.7,0.1-2.2,0.1-6.6s0-4.9-0.1-6.6c-0.1-1.7-0.3-2.9-0.7-3.9c-0.4-1.1-1-1.9-1.8-2.8
                c-0.9-0.9-1.8-1.4-2.8-1.8c-1-0.4-2.2-0.7-3.9-0.7C20.9,0,20.3,0,16,0z M16,7.8c-4.5,0-8.2,3.7-8.2,8.2s3.7,8.2,8.2,8.2
                s8.2-3.7,8.2-8.2S20.5,7.8,16,7.8z M16,21.3c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3s5.3,2.4,5.3,5.3S18.9,21.3,16,21.3z M26.4,7.4
                c0,1.1-0.9,1.9-1.9,1.9s-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9S26.4,6.4,26.4,7.4z"></path>
              </g>
            </svg>

          </a>
          <!-- linkedin logo and link -->
          <a href="https://www.linkedin.com/in/alex-bradt" target="_blank" rel="noopener noreferrer">
            <svg title="Go To MousePack LinkedIn Page" version="1.1" class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
              <g>
                  <path fill="#ffffff" d="M39.2,0H2.8C1.3,0,0,1.3,0,2.8v36.4C0,40.7,1.3,42,2.8,42h36.4c1.5,0,2.8-1.3,2.8-2.8V2.8
                      C42,1.3,40.7,0,39.2,0z M12.4,35.7H6.2v-20h6.2V35.7z M9.3,13c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6
                      C12.9,11.4,11.3,13,9.3,13z M35.7,24.3c0,1.3,0,11.4,0,11.4h-6.2c0,0,0-9.2,0-10.2c0-1.1,0.3-4.8-3.3-4.8c-3.7,0-3.6,4.1-3.6,5.4
                      c0,1.3,0,9.6,0,9.6h-6.2v-20h6v2.8c0,0,1.6-3.3,6.2-3.3C36.2,15.2,35.7,23,35.7,24.3z"></path>
                  <g>
                      <rect x="6.2" y="15.7" fill="none" width="6.2" height="20"></rect>
                      <circle fill="none" cx="9.3" cy="9.4" r="3.6"></circle>
                      <path fill="none" d="M22.6,35.7h-6.2v-20h6v2.8c0,0,1.6-3.3,6.2-3.3c7.7,0,7.2,7.8,7.2,9.1s0,11.4,0,11.4h-6.2c0,0,0-9.2,0-10.2
                          c0-1,0.3-4.8-3.3-4.8c-3.7,0-3.6,4.1-3.6,5.4C22.6,27.5,22.6,35.7,22.6,35.7z"></path>
                  </g>
              </g>
            </svg>
          </a>
          <!-- twitter logo and link -->
          <a href="https://mobile.twitter.com/AlexBradt" target="_blank" rel="noopener noreferrer">
            <svg title="Go To MousePack Twitter Page" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve">
              <g>
                <path fill="#ffffff" class="socialLogo" d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.8,3.8,24,3,22.2,3
                c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5
                c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.2-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1
                c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7
                c0-0.3,0-0.6,0-0.8C30,8.6,31.1,7.4,32,6.1"></path>
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div class="legal">
        <router-link to="/" class="logo">
          <div>
              <svg title="Go To MousePack Home Page" id="mousepack-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 57.34">
                <defs></defs><g id="mouse-shapes"><path class="cls-1" d="M100,44.6c0,11-6.66,19.53-16.12,21.83a19.09,19.09,0,0,1-7.18.37,2.17,2.17,0,0,1-.77-.28Q60.76,58.11,45.59,49.67a4.26,4.26,0,0,1-1.75-6.11L54.14,25c.17-.31.3-.6.78-.55a21.5,21.5,0,0,1,9.54,3l.56.38c.45.31.89.89,1.33.88s.89-.58,1.34-.9a20.42,20.42,0,0,1,30,7.89A20.76,20.76,0,0,1,100,44.6Z" transform="translate(0 -24.08)"/><path class="cls-1" d="M47.87,81.41a36.53,36.53,0,0,1-12.52-1.94,18.7,18.7,0,0,1-7.53-5,1,1,0,0,1-.17-1.36c3-5.38,6-10.79,9-16.18A4.29,4.29,0,0,1,43,55.14L72.67,71.63c.69.38.69.39.17,1a25.12,25.12,0,0,1-11.74,7A47.35,47.35,0,0,1,47.87,81.41Z" transform="translate(0 -24.08)"/><path class="cls-1" d="M20,24.08a20.44,20.44,0,0,1,14.15,5.21c.43.38.64.4,1.07,0a17.36,17.36,0,0,1,9.09-4.36c.94-.19,1.89-.29,2.83-.43.36-.06.55,0,.3.4s-.37.65-.56,1L35.6,46.11a4.3,4.3,0,0,1-6.32,1.8q-12.55-7-25.11-14c-.51-.29-.61-.49-.24-1C8.09,27.24,13.66,24.25,20,24.08Z" transform="translate(0 -24.08)"/><path class="cls-1" d="M.77,51.28a22,22,0,0,1,0-11.46c.18-.68.19-.69.81-.34L26.42,53.36a4.24,4.24,0,0,1,1.75,6c-1.24,2.24-2.5,4.47-3.73,6.71a1.05,1.05,0,0,1-.87.59C12.33,68.46,3.21,60.46.77,51.28Z" transform="translate(0 -24.08)"/></g>
            </svg>
          </div>
        </router-link>
        <span class="copyright">© 2023 MousePack</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return {
    }
  },
  methods: {
  },
  created() {
  }
}
</script>

<style>
  /* Footer Component 
  ----------------------------- */
  footer {
    /* background: #1e90ff; */
    color: #fff;
  }
  footer.night-mode {
    background: none;
  }
  footer .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }
  footer .logo {
    border: 3px solid transparent;
  }
  footer .logo:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  /* footer .logo div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  } */
  footer .links {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  footer .links .social-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  footer .logo svg {
    height: 100%;
  }
  footer .logo svg .cls-1 {
    fill:#fefefe;
  }
  footer .links svg {
    height: 50px;
  }
  footer a {
    display: inline-block;
    border: 3px solid transparent;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
  }
  footer a:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 8px;
    outline: 0;
  }
  footer .legal {
    /* display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center; */
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  /* footer .logo div {
    justify-content: flex-end;
  } */
  footer .links .email {
    /* width: auto;
    margin-right: 50px; */
    text-align: center;
  }
  footer .links .social-links a {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    /* margin-right: 60px; */
  }
  @media screen and (max-width: 800px) {
    footer a {
      /* font-size: 1.5rem; */
    }
    footer .logo div {
      /* height: 40px; */
    }
    footer .links .social-links a {
      /* height: 34px; */
      /* width: 34px; */
    }
    footer .links svg {
      /* height: 40px; */
    }
    footer .links .email {
      /* margin-right: 35px; */
    }
  }
  @media screen and (max-width: 700px) {
    /* footer a {
      font-size: 1.25rem;
    } */
    /* footer .links .email {
      margin-right: 25px;
    } */
  }
  @media screen and (max-width: 600px) {
    /* footer .links .email {
      margin-right: 10px;
    } */
    footer a {
      font-size: 1.5rem;
    }
    /* footer .logo div {
      height: 35px;
    } */
    footer .links svg {
      height: 50px;
    }
    /* footer .links .social-links a {
      width: 50px;
      height: 50px;
      margin-right: 30px;
    } */
  }
  @media screen and (max-width: 500px) {
    footer .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 15px;
    }
    footer .links {
      /* justify-content: center;
      width: 100%; */
      margin-bottom: 30px;
    }
    /* footer a {
      font-size: 1.5rem;
    } */
    footer .logo div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
    }
    footer .copyright {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /* footer .links .social-links a {
      height: 50px;
      width: 50px;
      margin-right: 0;
    } */
    /* footer .links .social-links {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
    } */
    footer .links svg {
      height: 50px;
    }
  }
  @media screen and (max-width: 400px) {
    footer .container {
      padding: 40px 15px;
    }
    /* footer .logo div {
      height: 35px;
    } */
    footer .links .social-links a {
      /* height: 35px; */
      /* width: 35px; */
    }
    footer .links svg {
      /* height: 35px; */
    }
  }
  @media screen and (max-width: 320px) {
    footer a {
      font-size: 1.1rem;
    }
  }
</style>