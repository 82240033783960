<template>
  <div class="newsfeed-crop">
    <div class="newsfeed">
      <div class="cube">

        <!-- NOTE: emojis can't be stored in json, so keep them hardcoded below  -->

        <div class="cube-face cube-front">
          <p>🎉 {{ newsData[0].name }} 
            <router-link :to="{ name: 'Article', params: {articleSlug: newsData[0].slug} }" @click="activeArticle = newsData[0]" tabindex="5">
              {{ newsData[0].linkText }}
            </router-link>
          </p>
        </div>
        <div class="cube-face cube-top">
          <p>🔥 {{ newsData[1].name }} 
            <router-link :to="{ name: 'Article', params: {articleSlug: newsData[1].slug} }" @click="activeArticle = newsData[1]" tabindex="6">
              {{ newsData[1].linkText }}
            </router-link>
          </p>
        </div>
        <div class="cube-face cube-back">
          <p>🎉 {{ newsData[2].name }} 
            <router-link :to="{ name: 'Article', params: {articleSlug: newsData[2].slug} }" @click="activeArticle = newsData[2]" tabindex="7">
              {{ newsData[2].linkText }}
            </router-link>
          </p>
        </div>
        <div class="cube-face cube-bottom">
          <p>🔥 {{ newsData[3].name }} 
            <router-link :to="{ name: 'Article', params: {articleSlug: newsData[3].slug} }" @click="activeArticle = newsData[3]" tabindex="8">
              {{ newsData[3].linkText }}
            </router-link>
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import NewsData from '../../assets/data/news.json'

export default {
  name: 'Newsfeed',
  data(){
    return {
      newsData: NewsData,
      activeArticle: null,
    }
  },
  methods: {

  },
  created() {

    let cube = document.getElementsByClassName("cube");
		let counter = 0;
		let degrees = 0;

		const cycleNews = () => {

			counter++;

			// rotate 90 degrees on every cycle
			degrees = counter * 90;

      // instead of adding inline style,
      // could add a class with these properties
			cube[0].style.transition = "all 0.2s ease-in";
			cube[0].style.transform = `rotateX(-${degrees}deg)`;

			setTimeout(() => {

				// if fully rotated, reset counter, degrees, and transform:rotate to 0 with CSS transition off
				if (counter === 4) {

					counter = 0;
					degrees = 0;
					cube[0].style.transition = "none";
					cube[0].style.transform = `rotateX(-${degrees}deg)`;

				}

			}, 250);
		};

		const startTimer = () => {
			// set rotation interval
			setInterval(cycleNews, 5000);
		};

		startTimer();

  }
}
</script>

<style scoped>
  /*Newsfeed
  ----------------------------- */
  a {
    font-weight: 700;;
    color: #ffc300;
  }
  .newsfeed-crop {
    overflow: hidden;
  }
  .newsfeed {
    display: block;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    perspective: 500px;
  }
  .cube {
    position: relative;
    width: 100%;
    height: 100%;
    background: #1e90ff;
    transform-style: preserve-3d;
  }
  .cube:hover {
    background: #ff6b81;
  }
  .cube-face {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    line-height: .9rem;
    background: inherit;
    transition: background .2s ease-in-out;
  }
  .cube-face p {
    padding: 0 15px;
    text-align: center;
    color: #fff;
  }
  .cube-face a {
    white-space: nowrap;
  }
  .cube-face a:focus-visible {
    padding: 3px;
    border: 3px solid #5551ff;
    border-radius: 4px;
    outline: 0;
  }
  .cube-front {
    transform: rotateY(0deg) translateZ(20px);
  }
  .cube-back {
    transform: rotateY(180deg) rotateZ(180deg) translateZ(20px);
  }
  .cube-top {
    transform: rotateX(90deg) translateZ(20px);
  }
  .cube-bottom {
    transform: rotateX(-90deg) translateZ(20px);
  }
  @media screen and (max-width: 400px) {
    .newsfeed {
      height: 50px;
    }
    .cube-face {
      height: 50px;
    }
    .cube-front {
      transform: rotateY(0deg) translateZ(30px);
    }
    .cube-back {
      transform: rotateY(180deg) rotateZ(180deg) translateZ(30px);
    }
    .cube-top {
      transform: rotateX(90deg) translateZ(30px);
    }
    .cube-bottom {
      transform: rotateX(-90deg) translateZ(30px);
    }
  }
</style>