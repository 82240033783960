import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home";
import Cases from "@/views/Cases";
import Work from "@/views/Work";
import About from "@/views/About";
// import ProtectedWork from "@/views/ProtectedWork";
// import News from "@/views/News";
import Project from "@/components/layout/Project";
import Article from "@/components/layout/Article";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [
			{
				path: "/cases/:projectSlug",
				name: "FeaturedProject",
				component: Project,
				props: true,
			},
		],
	},
	{
		path: "/cases",
		name: "Cases",
		component: Cases,
		children: [
			{
				path: "/cases/:projectSlug",
				name: "Project",
				component: Project,
				props: true,
			},
		],
	},
	{
		path: "/work",
		name: "Work",
		component: Work,
	},
	{
		path: "/about",
		name: "About",
		component: About,
	},
	// {
	// 	path: "/protected-work",
	// 	name: "Protected",
	// 	component: ProtectedWork,
	// 	children: [
	// 		{
	// 			path: "/protected/:projectSlug",
	// 			name: "ProtectedProject",
	// 			component: Project,
	// 			props: true,
	// 		},
	// 	],
	// },
	// {
	// 	path: "/news",
	// 	name: "News",
	// 	component: News,
	// 	children: [
	// 		{
	// 			path: "/news/:articleSlug",
	// 			name: "Article",
	// 			component: Article,
	// 			props: true,
	// 		},
	// 	],
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from) {
		console.log("this is the to value: ", to.name);
		console.log("this is the from value: ", from.name);
		if (to.name === "Cases" && from.name !== "Project") {
			return { top: 0 };
		}
		if (to.name === "News" && from.name !== "Article") {
			return { top: 0 };
		}
		if (to.name === "Home" && from.name !== "FeaturedProject") {
			return { top: 0 };
		}
		if (to.name === "About") {
			return { top: 0 };
		}
	},
});

export default router;
