<template>
  <!-- project grid display -->
  <div class="work">
    <div class="container">
      <ul>
        <li v-for="(gif) in gifs" :key="gif.id" class="work-item">
          <img :src="gif.url" :alt="gif.title">
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
    
  export default {
    name: 'Work',
    data(){
      return {
      gifUrls: [
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/jar-smash.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/gravity-gun.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/jenga.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/chug-jug.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/vps.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/antman.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/universal-gopher.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/cloud-catch.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/snow-cannon.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/llama-hat.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/box-bois.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/box-bois-unwrapped.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/espn.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/mouse-house.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/hotd.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/madison.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/google.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/pepsi.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/strange-world.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/spooky-smash-2.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/puzzle-park.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/pillow-punch.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/piggy-smash.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/gopher.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/statue-of-liberty.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/apple-ml.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/marvel.gif",
        "https://www.stevenwolpe.com/dev-assets/mpwebsite/gifs/singing.gif",
      ],
      gifs: [],
      // page: 0,
      loading: false,
      totalLoaded: 0,
      // initialLoad: true,
    }
    },
    methods: {
  
      loadMore() {
        this.loading = true;
        const gifsPerLoad = this.totalLoaded < 12 ? 12 : 4;
        const gifBatch = this.gifUrls.slice(this.totalLoaded, this.totalLoaded + gifsPerLoad);
        // const gifsPerLoad = this.initialLoad ? 12 : 4;
        // const gifBatch = this.gifUrls.slice(this.page * gifsPerLoad, (this.page * gifsPerLoad) + gifsPerLoad);
        // const gifBatch = this.gifUrls.slice(this.page * 3, (this.page * 3) + 3);

        gifBatch.forEach((url, i) => {
          this.gifs.push({
            id: this.totalLoaded + i,
            title: `Gif ${this.totalLoaded + i + 1}`,
            url: url,
          });
        });

        this.totalLoaded += gifBatch.length;
        this.loading = false;

        // gifBatch.forEach((url, i) => {
        //   this.gifs.push({
        //     id: this.page * gifsPerLoad + i,
        //     title: `Gif ${this.page * gifsPerLoad + i + 1}`,
        //     url: url,
        //   });
        // });

        // gifBatch.forEach((url, i) => {
        //   this.gifs.push({
        //     id: this.page * 3 + i,
        //     title: `Gif ${this.page * 3 + i + 1}`,
        //     url: url,
        //   });
        // });

        // this.page++;
        // this.loading = false;
        // this.initialLoad = false; // set to false after the first load
      },
      checkBottom() {
        const {scrollHeight, scrollTop, clientHeight} = document.documentElement;
        if(scrollTop + clientHeight >= scrollHeight - 5 && !this.loading) {
          this.loadMore();
        }
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
      this.loadMore();
      window.addEventListener('scroll', this.checkBottom);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.checkBottom);
    }
  }
  </script>
  
  <style scoped>
    /* 
    ----------------------------- */
    .work {
      padding: 0 15px;
      margin: 50px auto 80px;
    }
    .work .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 1100px;
      margin: 0 auto;
    }
    .work ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .work li {
      max-width: 200px;
      overflow: hidden;
    }
    .work li img {
      width: 200px;
      height: 355px;
      border-radius: 18px;
    }
    @media screen and (max-width: 1100px) {
     
    }
    @media screen and (max-width: 1000px) {
      .work ul {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    @media screen and (max-width: 900px) {
     
    }
     
    @media screen and (max-width: 800px) {
      
    }
    @media screen and (max-width: 700px) {
      .work ul {
        grid-template-columns: 1fr 1fr;
      }
    }
    @media screen and (max-width: 600px) {

    }
    @media screen and (max-width: 500px) {
      .work ul {
        grid-template-columns: 1fr;
        gap: 15px;
      }
      .work li {
        max-width: 250px;
      }
      .work li img {
        width: 250px;
        height: auto;
      }
    }
    @media screen and (max-width: 400px) {
     
    }
    @media screen and (max-width: 320px) {
     
    }
    /* mobile browsers with hover support */
    @media only screen and (hover: hover) and (pointer: coarse) {
    
    }
    /* mobile browsers with no hover support */
    @media only screen and (hover: none) and (pointer: coarse) {
     
    }
  
  </style>