<template>
<div class="first-brands">
  <h2>Social AR <span>Friends Can Share Instantly</span></h2>
  <div class="first-brands-logos container">
    <div class="client-logo">
      <img src="../../assets/images/snap-alt-logo.png" width="103" height="100" alt="SnapChat Logo">
    </div>
    <div class="client-logo">
      <img src="../../assets/images/instagram-logo.png" width="100" height="100" alt="Instagram Logo">
    </div>
    <div class="client-logo">
      <img src="../../assets/images/tiktok-logo.png" width="80" height="100" alt="TikTok Logo">
    </div>
    <div class="client-logo">
      <img src="../../assets/images/unity-logo.png" width="221" height="90" alt="Unity Logo">
    </div>
  </div>
</div>
  
</template>

<script>
export default {
  name: 'FirstBrands',
  data(){
    return {
      
    }
  },
  methods: {
   
  },
  created() {

  }
}
</script>

<style scoped>
  .first-brands {
    background: linear-gradient(to top, #f3f3f4, #e7e7e7);
    padding: 0 15px;
  }
  .first-brands .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    /* column-gap: 55px; */
    /* max-width: 1000px; */
    height: auto;
    /* max-height: 100px; */
    padding: 0 0 40px;
    /* margin-bottom: 40px; */
  }
  .first-brands h2 {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: #1e90ff;
  }
  .first-brands h2 span {
    display: inline-block;
  }
  .first-brands .client-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    padding: 20px 0;
    flex-grow: 0;
  }
  .first-brands .client-logo img {
    width: auto;
    max-width: 100%;
    max-height: 75px;
  }
  @media screen and (max-width: 500px) {
    .first-brands h2 span  {
      display: inline;
    }
  }
</style>