<template>
  <div id="process">
    <div class="container">

      <div id="process-header">
        <h2><span>How We Make the Cheese</span></h2>
        <!-- <p>Traditional advertisements struggle to keep up, AR offers an innovative way for brands to connect with their audience.</p> -->
      </div>
      <div id="process-body">
        <div class="process-stage">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/cheese-prototype.png" alt="Picture of cheese object at prototype stage.">
          <div class="stage-info">
            <h3>Prototype</h3>
            <p>Develop a clear vision for the user experience:</p>
            <ul>
              <li>identify core features</li>
              <li>discuss technical feasibility</li>
            </ul>
            <!-- <p>We provide a comprehensive timeline and statement of work to ensure the project is completed on time and within budget.</p> -->
          </div>
        </div>
        <div class="process-stage">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/cheese-alpha.png" alt="Picture of cheese object at aplha stage.">
          <div class="stage-info">
            <h3>Alpha</h3>
            <p>Begin to shape a minimum viable product:</p>
            <ul>
              <li>create 2D and 3D assets</li>
              <li>design the user experience</li>
              <li>integrating core features</li>
            </ul>
            <!-- <p>We gather feedback from you and identify areas for elevation.</p> -->
          </div>
        </div>
        <div class="process-stage">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/cheese-beta.png" alt="Picture of cheese object at beta stage.">
          <div>
            <h3 class="stage-info">Beta</h3>
            <p>Refine the experience in design and functionality:</p>
            <ul>
              <li>optimize the experience</li>
              <li>create visual and auditory effects</li>
              <li>ensure core functionality</li>
            </ul>
            <!-- <p>We complete rigorous testing to ensure the experience works on all intended platforms and devices. </p> -->
          </div>
        </div>
        <div class="process-stage">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/cheese-final.png" alt="Picture of cheese object at beta stage.">
          <div class="stage-info">
            <h3>Release</h3>
            <p>Provide your completed project:</p>
            <ul>
              <li>all requested deliverables</li>
              <li>launch and installation support</li>
            </ul>
            <!-- <p>You integrate the project into a broader campaign to highlight your brand and establish a powerful connection with your audience.</p> -->
          </div>
        </div>
      </div>
      
  </div>
  </div>
</template>

<script>
export default {
  name: 'Process',
  data(){
    return {
      
    }
  },
  methods: {
    
  },
  created() {

  }
}
</script>

<style scoped>

  div#process {
    padding: 0 15px;
    margin: 50px 0;
    /* max-width: 800px; */
  }
  #process-header {
    margin-bottom: 25px;
  }
  #process-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 20px;
  }

  #process-body ul {
    /* min-height: 60px; */
    /* margin-left: 20px; */
    margin-top: 12px;
    margin-bottom: 0;
    padding-left: 20px;
    color: #e7e7e7;
    font-size: .9rem;
    line-height: 1;
    font-weight: 600;
  }
  #process-body li {
    margin-bottom: 8px;
  }
  div#process .process-stage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* display: grid; */
    /* grid-template-columns: 1fr 3fr; */
    /* grid-template-columns: 1fr; */
    /* gap: 20px; */
    /* align-items: center; */
    padding: 5px;
    height: 100%;
    background: #1e1e1e;
    border-radius: 12px;
  }
  div#process .process-stage img {
    width: 50%;
    margin: 0 auto;
  }
  
  div#process .process-stage p {
    margin-top: 7px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 0.9rem;
    line-height: 1.6;
    color: #e7e7e7;
  }
  div#process .process-stage p:first-of-type {
    /* min-height: 50px; */
  }

  /* .stage-info {
    width: 75%;
  } */

  div#process h2 {
    font-size: 4rem;
    margin: 0;
    /* color: #1e90ff; */
    color: #ffffff;
  }

  div#process h3 {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: -10px;
    margin-left: 10px;
    color: #1e90ff;
  }

  div#process h3 span {
    font-weight: 600;
  }



  /* div#process p {
    margin-top: 7px;
    color: #e7e7e7;
    font-size: 1.2rem;
    line-height: 1.875;
  } */
  
  div#process div.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 1100px) {
    /* #process-body {
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      align-items: center;
    }
    div#process .process-stage {
      max-width: 66.666%;
      margin: 0 auto;
    } */
  }
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 900px) {
    /* div#process #process-header {
      margin: 0 50px;
    } */
    /* div#process #process-body {
      margin: 0 50px;
    } */
    /* div#process .process-stage img {
      width: 100%;
    } */
    div#process h2 {
      /* margin-left: 25px; */
      font-size: 3.5rem;
    }
  }
  @media screen and (max-width: 800px) {
    div#process h2 {
      font-size: 3.25rem;
    }
    div#process h3 {
      margin-top: 0;
    }
    /* div#process .process-stage {
      grid-template-columns: 1fr;
      gap: 0;
    } */
    /* div#process .process-stage img {
      width: 33.333%;
      margin: 0 auto;
      margin-bottom: -25px;
    } */
  }
  @media screen and (max-width: 700px) {
    /* div#process div.container {
      width: 90%;
    } */
    /* div#process #process-header {
      width: 90%;
      margin: 0 auto;
    } */
    /* div#process #process-body {
      width: 90%;
      margin: 0 auto;
    } */
    #process-body {
      grid-template-columns: 1fr 1fr;
      margin: 0 auto;
      gap: 50px;
    }
    div#process .process-stage {
      max-width: 200px;
      margin: 0 auto;
    }
    div#process h2 {
      margin-left: 0;
      font-size: 3rem;
    }
    div#process .process-stage img {
      width: 50%;
    }
  }
  @media screen and (max-width: 600px) {
    div#process h2 {
      font-size: 2.75rem;
    }
    /* div#process h3 {
      margin-bottom: -4px;
      margin-left: 15px;
      font-size: 3rem;
    }
    div#process h3:first-of-type {
      margin-top: 20px;
    }
    div#process h3 span {
      display: block;
      margin-bottom: -16px;
      margin-left: -15px;
      font-size: 2.25rem;
    } */
  }
  @media screen and (max-width: 500px) {
    /* div#process #process-body {
      width: 100%;
    }
    div#process #process-header {
      width: 100%;
    } */
    #process-body {
      gap: 30px;
    }
    div#process h2 {
      font-size: 2.5rem;
    }
    /* div#process h2 span {
      display: block;
      margin-bottom: -24px;
    } */
  }
  @media screen and (max-width: 400px) {
    div#process div.container {
      width: 100%;
    }
    #process-body {
      grid-template-columns: 1fr;
      margin: 0 auto;
    }
    div#process .process-stage {
      max-width: 225px;
    }
    /* div#process h2 {
      font-size: 3.25rem;
    } */
    div#process h2 {
      font-size: 2.25rem;
    }
    div#process p {
      font-size: 1.4rem;
    }
    /* div#process .process-stage img {
      width: 75%;
    } */
    /* #process-body ul {
      font-size: 1.33rem;
    } */
  }
  @media screen and (max-width: 320px) {
    /* div#process h2 {
      font-size: 3rem;
    } */
    /* div#process h3 {
      font-size: 2.75rem;
    } */
  }
  /* mobile browsers with hover support */
  @media only screen and (hover: hover) and (pointer: coarse) {
    
  }
  /* mobile browsers with no hover support */
  @media only screen and (hover: none) and (pointer: coarse) {
    
  }
</style>