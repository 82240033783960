<template>
  <div class="content">
    <Navbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>

import Newsfeed from '@/components/layout/Newsfeed'
import Navbar from '@/components/layout/Navbar'
import Footer from '@/components/layout/Footer'

export default {
  
  name: 'App',
  components: {
    Newsfeed,
    Navbar,
    Footer
  },
  data() {
    return {
      
    }
  },
  methods: {
    allowScroll() {
      let body = document.querySelector("body")
      body.classList.remove("no-scroll")
    }
  },
  created() {

  },
  watch: {
    $route (to, from){
      if(to.name === "Cases" || to.name === "Protected" || to.name === "Home" || to.name === "News"){
        this.allowScroll()
      }
    }
  }

}
</script>

<style>
  body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    color: #403E43;
    scrollbar-width: 16px;
    scrollbar-gutter: stable;
    overflow-y: scroll;
  }
  body.no-scroll {
    overflow: hidden;
    padding-right: 16px;
  }
  /* .pink-text {
    color: #ff6b81;
    font-size: 1.66rem;
    line-height: 1rem;
  } */
  /* mobile browsers with hover support */
  @media only screen and (hover: hover) and (pointer: coarse) {
    /* mobile browser scrollbar doesn't take up any space in the viewport,
    so do not add padding-right when the scrollbar is gone */
    body.no-scroll {
      padding-right: 0;
    }
  }
  /* mobile browsers with no hover support */
  @media only screen and (hover: none) and (pointer: coarse) {
    /* mobile browser scrollbar doesn't take up any space in the viewport,
    so do not add padding-right when the scrollbar is gone */
    body.no-scroll {
      padding-right: 0;
    }
  }
  /* Night Mode: Global Settings 
  ----------------------------- */
  body.night {
    background: #121212;
  }
  body.night-mode header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

</style>
