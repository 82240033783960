<template>
  <!-- project grid display -->
  <div class="featured-projects">
    <div class="container">
      <ul>
        <li v-for="(item) in filteredProjects" :key="item.name" class="project-item">

          <!-- TO-DO: make this image a responsive <picture> ?? -->
          <img v-if="!item.videoPreview" width="335" height="251" :src="item.previewImage" :alt="item.altText" class="project-image" @pointerenter.passive="projectInteraction($event, item.id)" @pointermove.passive="projectInteraction($event, item.id)">

          <video v-else width="335" height="251" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" class="project-image" @pointerenter.passive="projectInteraction($event, item.id)" @pointermove.passive="projectInteraction($event, item.id)" tabindex="-1">

            <source :src="item.previewImage" type="video/mp4">
            <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
            <!-- TO-DO: add source(s) for webm and mp4 -->
            <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
            <!-- TO-DO: try adding img file for (same as poster) for fallback -->
        
          </video>
          <span class="tap-preview-indicator">
            Tap to Preview
          </span>
          <div class="project-info">
            <h3 v-if="item.shortName">{{ item.shortName }}</h3>
            <h3 v-else>{{ item.name }}</h3>
            <div>
              <p>{{ item.desc }}</p>
            </div>
            <!-- <router-link :to="{ name: 'FeaturedProject', params: { projectSlug: item.slug }}" @click="activeProject = item">
              <svg title="Open Project" class="open-project" id="project-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" tabindex="-1">
                <g id="circle"><circle class="cls-1" cx="25" cy="25" r="24.5"/><path class="cls-2" d="M25,1A24,24,0,1,1,1,25,24,24,0,0,1,25,1m0-1A25,25,0,1,0,50,25,25,25,0,0,0,25,0Z"/></g><g id="plus-shadow"><polygon class="cls-2" points="39 24 39 30 30 30 30 39 24 39 24 30 15 30 15 24 24 24 24 15 30 15 30 24 39 24"/></g><g id="plus-symbol"><polygon class="cls-3" points="37 22 37 28 28 28 28 37 22 37 22 28 13 28 13 22 22 22 22 13 28 13 28 22 37 22"/></g>
              </svg>
            </router-link> -->
          </div>
          <router-link :to="{ name: 'Project', params: { projectSlug: item.slug }}" @click="activeProject = item" class="open-project-link">
            <button id="project-button" class="open-project">See Case Study</button>
            <!-- <svg title="Open Project" class="open-project" id="project-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" tabindex="-1">
              <g id="circle"><circle class="cls-1" cx="25" cy="25" r="24.5"/><path class="cls-2" d="M25,1A24,24,0,1,1,1,25,24,24,0,0,1,25,1m0-1A25,25,0,1,0,50,25,25,25,0,0,0,25,0Z"/></g><g id="plus-shadow"><polygon class="cls-2" points="39 24 39 30 30 30 30 39 24 39 24 30 15 30 15 24 24 24 24 15 30 15 30 24 39 24"/></g><g id="plus-symbol"><polygon class="cls-3" points="37 22 37 28 28 28 28 37 22 37 22 28 13 28 13 22 22 22 22 13 28 13 28 22 37 22"/></g>
            </svg> -->
          </router-link>

          <div class="video-overlay" @pointerleave.passive="projectInteraction($event, item.id)" @touchstart.passive="projectInteraction($event, item.id)" @touchmove.passive="projectInteraction($event, item.id)" tabindex="-1">
            <video width="335" height="595" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" tabindex="-1">
              <source :src="item.video" type="video/mp4" tabindex="-1">
              <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
              <!-- TO-DO: add source(s) for webm and mp4 -->
              <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
              <!-- TO-DO: try adding img file for (same as poster) for fallback -->
            </video>
          </div>
        </li>
      </ul>
      <router-link to="/cases" class="more-work" tabindex="-1">
        <button>More Cases</button>
      </router-link>
    </div>
  </div>
  <!-- this where the project (route) is displayed -->
  <router-view :modalOrigin="modalOrigin" :projectData="activeProject"></router-view>
</template>

<script>

import ProjectsData from '../../assets/data/projects.json'

export default {
  name: 'FeaturedProjects',
  data(){
    return {
      modalOrigin: "home",
      projectsData: ProjectsData,
      activeProject: null,
      projectPreviewOpened: [],
      openProjectTimeoutID: null,
      openProjectPreviewOnTouch: null,
      closeProjectPreviewOnTouch: null,
      touchScrolling: false,
      touchmoveCounter: 0,
      touchScrollYStart: 0,
      touchScrollYDiff: 0
    }
  },
  methods: {
    projectInteraction(event, projectID) {

      // console.log("1. start projectInteraction")
      // console.log("2. this is the projectPreviewOpened value for this item: ", this.projectPreviewOpened[projectID])
      // console.log("3. this is the projectID: ", projectID)
      // console.log("4. this is the event: ", event)
      // console.log("5. this is the event type: ", event.type)
      // console.log("6. this is the pointer type: ", event.pointerType)
      // console.log("7. this is the event target: ", event.target)
      // console.log("8. this is the event parent element: ", event.target.parentElement)

      let parentElement = event.target.parentElement
      if(!parentElement.classList.contains("project-item")){
        // parent element is not project-item
        // get next parent element (which is project-item)
        parentElement = parentElement.parentElement
      }
     
      let overlay = parentElement.querySelector(".video-overlay")
      let info = parentElement.querySelector(".project-info")
      let button = parentElement.querySelector(".open-project")

      if (

        !this.projectPreviewOpened[projectID] &&     
        event.target.className === "project-image" && 
        event.pointerType === "mouse" && 
        event.type === "pointermove"
        
        ){

        // user is opening a project preview (mouse: pointermove)
        // console.log("mouse: pointermove: OPEN project preview")

        overlay.classList.add("active")     // add display:block to overlay element (which makes overlay visible/readable)
        setTimeout(() => {
					overlay.classList.add("visible"); // add opacity:1 to overlay element after delay (which fades in video)
				}, 1);
        button.classList.add("hide")        // add opacity:0 to button element (so it fades as info shrinks)
        info.classList.add("hide")          // add flex:0 to info element (which shrinks info to nothing, and reveals entire overlay)
        this.openProjectTimeoutID = setTimeout(() => {
					info.classList.add("none");       // add display:none to info element after delay (so screen readers don't pick it up when hidden)
          this.projectPreviewOpened[projectID] = true; // mark this project preview as opened
				}, 500);                            // this delay allows user to see animation of info element sliding out bottom of viewport 
      } else if (

        !this.projectPreviewOpened[projectID] &&
        event.target.className === "project-image" &&
        event.pointerType === "touch" &&
        (event.type === "touchstart" || event.type === "pointerenter")

      ){

        // console.log("touch: pointerenter: OPEN project preview: start timeout")

        if(this.touchScrolling){
          this.touchScrolling = false
        }

        if(this.touchmoveCounter > 0){
          this.touchmoveCounter = 0
        }

        if(this.touchScrollYDiff > 0){
          this.touchScrollYDiff = 0
        }

        this.touchScrollYStart = event.pageY

        // console.log("this is the initial touchScrollYStart value: ", this.touchScrollYStart)

        this.openProjectPreviewOnTouch = setTimeout(() => {

          // user is opening a project preview with touch
          // console.log("touch: pointerenter: timeout complete: OPEN project preview")

          overlay.classList.add("active")     
          setTimeout(() => {
            overlay.classList.add("visible"); 
          }, 1);
          button.classList.add("hide")       
          info.classList.add("hide")       
          this.openProjectTimeoutID = setTimeout(() => {
            info.classList.add("none");       
            this.projectPreviewOpened[projectID] = true; 
          }, 500);                            
        // wait to see if touchmove event occurs (user is scrolling on touch)
        // if consecutive touchmove events result in Y axis position changing the project preview will not open
        }, 250)

      } else if (
        
        !this.touchScrolling &&
        event.target.className === "project-image" &&
        (event.type === "touchmove" || event.type === "pointermove")

      ){

        // console.log("user maybe scrolling on touch device, check y axis movement")

        if(this.touchmoveCounter < 4){
          this.touchmoveCounter = ++this.touchmoveCounter
        }
        
        if(

          this.openProjectPreviewOnTouch !== null &&
          this.touchmoveCounter <= 4

        ){

          // console.log("This is event.pageY: " + event.pageY + ". And this is touchScrollYStart: " + this.touchScrollYStart + ".")

          if(event.pageY >= this.touchScrollYStart){

            this.touchScrollYDiff = event.pageY - this.touchScrollYStart

          } else {

            this.touchScrollYDiff = this.touchScrollYStart - event.pageY

          }

          // console.log("this is the touchScrollYDiff: ", this.touchScrollYDiff)

          if(this.touchScrollYDiff > 1){

            // console.log("The y diff is more than 1, clear the open project timeout.")
            clearTimeout(this.openProjectPreviewOnTouch)
            // prevent code above from executing on the next consecutive move events
            this.touchScrolling = true

          }

        }
        
      } else if (

        event.target.classList.contains("video-overlay") &&
        event.pointerType === "mouse" &&
        event.type === "pointerleave" ||

        // TO-DO: comment this out and test for neccessity
        // check if project preview is marked as open but video overlay did not receive "active" class
        this.projectPreviewOpened[projectID] && !overlay.classList.contains("active") 

      ){
        // user is closing a project preview
        if(!this.projectPreviewOpened[projectID]){

          // console.log("CLEAR open project timeout")
          // user has closed project preview before it completed opening

          clearTimeout(this.openProjectTimeoutID)
        }

        // console.log("pointerleave: CLOSE project preview")

        info.classList.remove("none")
        setTimeout(() => {
					info.classList.remove("hide")
          button.classList.remove("hide")
				}, 1);
				setTimeout(() => {
          overlay.classList.remove("visible")
				}, 200);
        setTimeout(() => {
					overlay.classList.remove("active")
          this.projectPreviewOpened[projectID] = false;
				}, 400);
        
      } else if (

        event.target.tagName === "VIDEO" &&
        event.type === "touchstart"

      ){

        // console.log("touchstart: CLOSE the project preview: start timeout")

        if(this.touchScrolling){
          // everytime a touchstart event occurs touchScrolling is reset to false
          this.touchScrolling = false
        }

        this.closeProjectPreviewOnTouch = setTimeout(() => {
          // user is closing a project preview with touch
          if(!this.projectPreviewOpened[projectID]){

            // console.log("CLEAR open project timeout")
            // user has closed project preview before it completed opening

            clearTimeout(this.openProjectTimeoutID)
          }

          // console.log("touchstart: timeout complete: CLOSE project preview")
          
          info.classList.remove("none")
          setTimeout(() => {
            info.classList.remove("hide")
            button.classList.remove("hide")
          }, 250);//1
          setTimeout(() => {
            overlay.classList.remove("visible")
          }, 450);//200
          setTimeout(() => {
            overlay.classList.remove("active")
            this.projectPreviewOpened[projectID] = false;
          }, 600);//400
        // wait to see if touchmove event occurs (user is scrolling on touch)
        // TO-DO: can we make this delay shorter? if so, shorten (3) delays above same amount
        }, 250) 

      } else if (
        
        !this.touchScrolling &&
        event.target.tagName === "VIDEO" &&
        event.type === "touchmove"

      ){

        // console.log("user is scrolling on touch device, cancel closeProjectPreviewOnTouch")

        if(this.closeProjectPreviewOnTouch !== null){

          clearTimeout(this.closeProjectPreviewOnTouch)
          this.touchScrolling = true

        }
        
      }
    },

  },
  computed: {
    filteredProjects() {
      return this.projectsData.filter(project => {
        let projectIndex = this.projectsData.indexOf(project)
        // set filteredProjects value to array of first 3 project objects in json file
        return projectIndex < 3
      })
    }
  },
  created() {
  },
  mounted() {

    const videoPreviewHandler = () => {
      // get all video preview elements on page
      let videoPreviews = document.querySelectorAll("video.project-image")
      // pause all videos initially
      videoPreviews.forEach((video) => {
        video.pause()
      })
      // setup promise to act as delay between videos playing
      const videoDelay = (milliseconds) => {
        return new Promise(resolve => {
          setTimeout(resolve, milliseconds)
        })
      }
      // loop over video elements and play/pause each
      async function videoController() {
        for (var i=0;;i++){
          videoPreviews[i].play()
          await videoDelay(3000)
          videoPreviews[i].pause()
          if(i === videoPreviews.length - 1) {
            i = -1
          }
        }
      }
      videoController()
    }
    videoPreviewHandler()
  }
}
</script>

<style scoped>
  /* Featured Projects
  ----------------------------- */
  div.featured-projects {
    padding: 0 15px;
    margin: 50px auto 80px;
  }
  div.featured-projects div.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
  }
  div.featured-projects ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  div.featured-projects li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: calc((1100px - 95px)/3);  */
    /* height: calc( ((1100px - 95px)/3) * 1.777); */
    width: 335px;
    height: 595.295px;
    border-radius: 15px;
    margin: 0 auto;
    overflow: hidden;
    transition: filter .2s cubic-bezier(.68,-.25,.265,1.25);
  }
  div.featured-projects li:hover {
    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, .15));
  }
  div.featured-projects .more-work {
    margin-top: 25px;
  }
  div.featured-projects .more-work button {
    padding: 12px 16px;
    border: 3px solid transparent;
    border-radius: 10px;
    background: #ff6b81;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s;
  }
  div.featured-projects .more-work button:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 32px;
  }
  div.featured-projects .more-work button:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  .project-item {
    /* background: #e6ecf0; */
    height: 100%;
  }
  .project-image {
    flex: 1 1 1px;
    width: 100%;
    cursor: pointer;
  }
  .tap-preview-indicator {
    display: none;
    position: absolute;
    top: 30%;
    padding: 10px 15px;
    border-radius: 20px;
    background: rgba(0,0,0,.2);
    font-size: .9rem;
    line-height: 1;
    letter-spacing: 2px;
    color: #ffffff;
    pointer-events: none;
  }
  .project-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 50%;
    z-index: 333;
    width: 100%;
    padding: 0 15px;
    background: #f3f3f4;
    background: -webkit-linear-gradient(to right, #f3f3f4, #e7e7e7);
    background: linear-gradient(to top, #f3f3f4, #e7e7e7);
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, .1);
    transition: all 0.5s ease;
  }
  .night .project-info {
    background: #1e1e1e;
    color: #e7e7e7;
  } 
  .project-info.hide {
    flex: 0;
  }
  .project-info.none {
    display: none;
  }
  .project-info h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    border-bottom: 1px solid rgba(64, 62, 67, .2);
    padding-bottom: 5px;
    margin: 20px 0 20px;
    font-size: 1.5rem;
  }
  .project-info p {
    font-size: 1.2rem;
    line-height: 1.421;
    margin-top: 0;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-clamp: 4;
    box-orient: vertical;;
  }
  @-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
  @keyframes bounce {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .project-info a {
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    border: 3px solid transparent;
    opacity: 1;
    cursor: pointer;
    /* transition: all .3s ease; */

  }
  .project-info a:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 50%;
    outline: 0;
  }
  a.open-project-link {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  button.open-project {
    position: absolute;
    bottom: 30px;
    z-index: 999;
    padding: 12px 16px;
    border: 3px solid transparent;
    border-radius: 10px;
    background: #ff6b81;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    transition: transform .2s ease 0s,background .2s ease 0s;
  }
  .project-info svg {
    /* position: absolute;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    opacity: 1;
    cursor: pointer;
    transition: all .3s ease; */
  }
  
  .project-info .cls-1 {
    fill:#ff6b81;
  }
  .project-info .cls-2 {
    fill:#d85d75;
  }
  .project-info .cls-3 {
    fill:#fff;
  }
  .project-info svg.hide {
    opacity: 0;
  }
  .project-info svg:hover {
    transform: translateY(-3px);
    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, .3));
    animation-duration: 0.6s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
  }
  #plus-shadow {
    display: none;
  }
  .project-info svg:hover #plus-shadow {
    display: block;
  }
  .project-item .video-overlay {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0;
    /* cursor: pointer; */
    transition: opacity 0.2s ease;
  }
  /* .project-item .video-overlay:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 50%;
    outline: 0;
  } */
  .project-item .video-overlay video {
    width: 100%;
    height: 100%;
  }
  .project-item .video-overlay.active {
    display: block;
  }
  .project-item .video-overlay.visible {
    opacity: 1;
  }

  @media screen and (max-width: 1100px) {
    div.featured-projects li {
      width: calc(33.33333vw - 31.66667px);
      height: calc(59.23333vw - 56.27167px);
      /* width: calc((100vw - 95px)/3);
      height: calc(((100vw - 95px)/3) * 1.777); */
    }
  }
  @media screen and (max-width: 1000px) {
    .project-info p {
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
  }
  @media screen and (max-width: 900px) {
    div.featured-projects ul {
      grid-template-columns: 1fr 1fr;
    }
    div.featured-projects li {
      width: calc(50vw - 35px);
      height: calc(88.85vw - 62.195px);
      max-width: 335px;
      max-height: 595px;
    }
    /* .project-item .project-info h3 {
      padding-bottom: 12px;
      margin: 12px 0 8px;
    } */
    .project-info p {
      -webkit-line-clamp: 4;
      line-clamp: 4;
    }
  }
  @media screen and (max-width: 800px) {
    li .project-image {
      flex: 1 1 25%;
    }
    li .project-info {
      flex: 1 1 75%;
      padding: 0 15px;
    }
    /* .project-item .project-info h3 {
      padding-bottom: 5px;
      margin: 5px 0 5px;
    } */
    .project-info a {
      right: 15px;
      bottom: 15px;
      width: 45px;
      height: 45px;
    }
  }
  @media screen and (max-width: 700px) {
    /* div.featured-projects ul {
      grid-template-columns: 1fr 1fr;
    }
    div.featured-projects li {
      width: calc((100vw - 70px)/2);
      height: calc(((100vw - 70px)/2) * 1.777);
    }
    .project-item .project-info h3 {
      padding-bottom: 15px;
      margin: 20px 0 20px;
    } */
    .project-info p {
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
    .project-item .project-info p {
      margin-top: 0;
      font-size: 1.125rem;
    }
    .project-info a {
      right: 30px;
      bottom: 30px;
      width: 50px;
      height: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    div.featured-projects ul {
      grid-template-columns: 1fr;
    }
    div.featured-projects li {
      width: 280px;
      height: 497.56px;
    }
    /* .project-item .project-info h3 {
      padding-bottom: 5px;
      margin: 5px 0 5px;
    } */
    .project-info a {
      right: 15px;
      bottom: 15px;
      width: 45px;
      height: 45px;
    }
  }
  @media screen and (max-width: 500px) {
    /* div.featured-projects ul {
      grid-template-columns: 1fr;
    }
    div.featured-projects li {
      width: 280px;
      height: calc(280px * 1.777);
    }
    .project-item .project-info h3 {
      padding-bottom: 15px;
      margin: 20px 0 20px;
    } */
    .project-info a {
      right: 30px;
      bottom: 30px;
      width: 50px;
      height: 50px;
    }
  }
  @media screen and (max-width: 320px) {
    div.featured-projects {
      margin: 30px 0 40px;
    }
    div.featured-projects li {
      /* width: calc(100vw - 30px);
      height: calc((100vw - 30px) * 1.777); */
      width: calc(100vw - 30px);
      height: calc(177.7vw - 53.31px);
    }
    /* .project-item .project-info h3 {
      padding-bottom: 12px;
      margin: 12px 0 8px;
    } */
    .project-info p {
      margin-top: 0;
      font-size: 1rem;
    }
  }
  /* mobile browsers with hover support */
  @media only screen and (hover: hover) and (pointer: coarse) {
    .tap-preview-indicator {
      display: block;
    }
  }
  /* mobile browsers with no hover support */
  @media only screen and (hover: none) and (pointer: coarse) {
    .tap-preview-indicator {
      display: block;
    }
  }

</style>

 