<template>

  <!-- 3D artwork with call to action -->
  <div class="hero">
    <div class="container">
      
      <div class="cta">
        <!-- <h1>Augmented Reality from <span>Concept to Conclusion</span></h1> -->
        <h1>Augmented Reality from Concept to Conclusion</h1>
        <h2>We are MousePack</h2>
        <p>A collection of talented creators with a shared passion for innovating with Augmented Reality. Based in NYC, our studio delivers distinguished work for leading brands across the globe.</p>
        
        <router-link to="/work">
          <button>Examples</button>
        </router-link>
        <!-- <span @click="toggleReel" class="hover">
              <button>View Reel</button>
            </span> -->

        <!-- <div class="lottie-button" @keyup.enter="toggleReel" @click="toggleReel" tabindex="9">
            <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_ygwbki4o.json" background="transparent"  speed="1"  style="width: 300px; height: 300px;" loop></lottie-player>
        </div> -->
        
        <!-- <button @keyup.enter="toggleReel" @click="toggleReel" tabindex="9">Watch Reel</button> -->

        <!-- <lottie-player src="https://assets2.lottiefiles.com/private_files/lf30_a7ayxlfw.json" background="transparent" speed=".75" style="width: 300px; height: 300px;" loop autoplay>
        </lottie-player> -->

      </div>

      <!-- TBD: video loop(s) that change/image map easter eggs -->
      <video width="900" height="720" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" tabindex="-1">
        <source src="https://www.stevenwolpe.com/dev-assets/mpwebsite/videos/mousepack-lab-dark.mp4" type="video/mp4">
        
        <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
        <!-- TO-DO: add source(s) for webm and mp4 -->
        <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
        <!-- TO-DO: try adding img file for (same as poster) for fallback -->

      </video>
      

      <!-- <iframe width="1000" height="1000" src="https://www.youtube.com/embed/h7AqJ9UaK1E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      <!-- <div class="youtube"> -->
      <!-- <iframe :style="cssVars" id="player" frameborder="0" allowfullscreen="1"  title="MousePack Reel" width="800" height="800" src="https://www.youtube.com/embed/h7AqJ9UaK1E?autoplay=1&controls=1&rel=0"></iframe> -->

      <!-- <div class="first-brands">
        <h2>Social AR Experiences <span>Friends Can Share Instantly</span></h2>
        <div class="first-brands-logos container">
          <div class="client-logo">
            <img src="../assets/images/snap-alt-logo.png" width="103" height="100" alt="SnapChat Logo">
          </div>
          <div class="client-logo">
            <img src="../assets/images/instagram-logo.png" width="100" height="100" alt="Instagram Logo">
          </div>
          <div class="client-logo">
            <img src="../assets/images/tiktok-logo.png" width="80" height="100" alt="TikTok Logo">
          </div>
          <div class="client-logo">
            <img src="../assets/images/unity-logo.png" width="221" height="90" alt="Unity Logo">
          </div>
        </div>
      </div> -->
      <!-- </div> -->
    </div>
  </div>


  <InstagramFeed/>
  <!-- <NewsLetterPreview/> -->
  <FeaturedProjects/>
  <Clients/>
  <NewsLetterSignup/>
  <!-- <Reel/> -->
  <!-- <FirstBrands/> -->
  <!-- <Clients/> -->
  <!-- <div v-if="reelOpen" class="reel-modal" @click="toggleReel"> -->
  <!-- <div v-if="reelOpen" class="reel-modal" @click="toggleReel"> -->
    <!-- <div class="container"> -->

      <!-- src="https://www.youtube.com/embed/P7YOYBMDXiw?autohide=1&amp;autoplay=1&amp;controls=0&amp;enablejsapi=1&amp;iv_load_policy=3&amp;loop=0&amp;modestbranding=1&amp;playsinline=1&amp;rel=0&amp;showinfo=0&amp;wmode=opaque&amp;origin=https%3A%2F%2Fwww.mousepack.com&amp;widgetid=1" -->
      
      <!-- <iframe id="player" frameborder="0" allowfullscreen="1"  title="MousePack Reel" width="640" height="360" src="https://www.youtube.com/embed/P7YOYBMDXiw?autoplay=1&controls=0&rel=0"></iframe> -->

      <!-- <video width="750" height="750" controls="true" autoplay="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" tabindex="-1"> -->
        <!-- <source src="https://www.stevenwolpe.com/dev-assets/mpwebsite/videos/mousepack-reel-750.mp4" type="video/mp4"> -->

      <!-- </video> -->

    <!-- </div> -->
      <!-- <div class="close-modal" @keyup.enter="toggleReel"> -->
      <!-- <div class="icon-container" tabindex="0"> -->
        <!-- <svg id="close-modal-icon" class="close-icon" data-name="close-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.85 104.85"> -->
          <!-- <title>Close Button</title> -->
          <!-- <rect x="355.51" y="155.61" width="20" height="140" rx="10" ry="10" transform="translate(-46.5 -365.56) rotate(45)" fill="#dbdbde"></rect><rect x="355.51" y="155.61" width="20" height="140" rx="10" ry="10" transform="translate(470.41 -46.5) rotate(135)" fill="#dbdbde"></rect> -->
        <!-- </svg> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </div> -->
</template>

<script>

// import Reel from '@/components/layout/Reel'
import InstagramFeed from '@/components/layout/InstagramFeed'
import NewsLetterPreview from '@/components/layout/NewsLetterPreview'
import NewsLetterSignup from '@/components/layout/NewsLetterSignup'
import FirstBrands from '@/components/layout/FirstBrands'
import Clients from '@/components/layout/Clients'
import FeaturedProjects from '@/components/layout/FeaturedProjects'

export default {
  name: 'Home',
  components: {
    InstagramFeed,
    NewsLetterPreview,
    NewsLetterSignup,
    // Reel,
    FirstBrands,
    Clients, 
    FeaturedProjects
  },
  data(){
    return {
      reelOpen: false,
      handleTab: null,
      mouseOverLottie: null,
      mouseLeaveLottie: null,
      // reelHeight: 800,
    }
  },
  methods: {
    toggleReel() {
      let body = document.querySelector("body")
      if(body.classList.contains("no-scroll")){
        document.removeEventListener("keydown", this.handleTab)
        body.classList.remove("no-scroll")
        console.log("remove keydown listener now")
        // this.handleTab = null
        this.reelOpen = !this.reelOpen
      } else {
        this.handleTab = (event) => {
          if(
            // verify event occured in the modal
            event.target.parentElement.classList.contains("close-modal")
          ) {
            console.log("The event occured inside the modal.")
            // TO-DO: rename .icon-container for clarity here... should indicate close button
            document.querySelector(".icon-container").focus()
          } else {
            console.log("The event occured outside the modal.")
            event.preventDefault();
            // TO-DO: rename .icon-container for clarity here... should indicate close button
            document.querySelector(".icon-container").focus()
          }
        }
        body.classList.add("no-scroll")
        document.addEventListener("keydown", this.handleTab)
        console.log("add keydown listener now")
        this.reelOpen = !this.reelOpen
      }
    },
    // need to call this everytime the window size is changed
    // adjustReelHeight() {
    //   let reelWidth = document.querySelector("iframe#player").clientWidth;
    //   console.log("this is the reel width: ", reelWidth)
    //   this.reelHeight = reelWidth;
    // }
    // openReel() {

    //   this.handleTab = (event) => {
    //     if(
    //       // verify event occured in the modal
    //       event.target.parentElement.classList.contains("body-copy") ||
    //       event.target.parentElement.classList.contains("close-modal")
    //     ) {
    //       console.log("The event occured inside the modal.")
    //       // TO-DO: verify the currrently focused element is inside the modal 
    //       // (if last tab was from inside modal, it could have landed outside modal)
    //     } else {
    //       console.log("The event occured outside the modal.")
    //       event.preventDefault();
    //       // TO-DO: find first focusable element in modal
    //       document.querySelector(".icon-container").focus()
    //     }
    //   }

    //   this.reelOpen = true
    //   let body = document.querySelector("body")
    //   body.classList.add("no-scroll")
    //   document.addEventListener('keydown', this.handleTab)
    //   console.log("added keydown listener")

    // },
    // closeReel() {
    //   document.removeEventListener('keydown', this.handleTab)
    //   this.reelOpen = false
    //   this.handleTab = null
    //   let body = document.querySelector("body")
    //   body.classList.remove("no-scroll")
    //   console.log("removed keydown listener")

    // }
  },
  computed: {
    cssVars() {
      // return {
      //   '--reelHeight': this.reelHeight + 'px'
      // }
    },
    // cssReelHeight() {
    //   let reelWidth = document.querySelector("iframe#player").clientWidth;
    //   let calculatedReelHeight = reelWidth;
    //   return calculatedReelHeight
    // },
    articleLinks() {
      
      let articleLinksToDisplay = []
      for (let i = 0; i < 4; i++) {
        let item = this.newsData[i]
        // check if current item is not the same as opened article
        if(item.id !== this.activeContent.id) {
          if(articleLinksToDisplay.length === 3) break
          articleLinksToDisplay.push(item)
        }
      }
      return articleLinksToDisplay

    }
  }, created() {
    // window.addEventListener("resize", this.adjustReelHeight);
  }
  // mounted() {
    // List of Video Vendors embeds you want to support
      // var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

      // console.log("these are the vids to make responsive: ", players);

      // // Select videos
      // var fitVids = document.querySelectorAll(players.join(","));

      // console.log("these are the vids we found: ", fitVids);

      // // If there are videos on the page...
      // if (fitVids.length) {
      //   // Loop through videos
      //   for (var i = 0; i < fitVids.length; i++) {
      //     // Get Video Information
      //     var fitVid = fitVids[i];
      //     var width = fitVid.getAttribute("width");
      //     var height = fitVid.getAttribute("height");
      //     var aspectRatio = height / width;
      //     var parentDiv = fitVid.parentNode;

      //     // Wrap it in a DIV
      //     var div = document.createElement("div");
      //     div.className = "fitVids-wrapper";
      //     div.style.paddingBottom = aspectRatio * 100 + "%";
      //     parentDiv.insertBefore(div, fitVid);
      //     fitVid.remove();
      //     div.appendChild(fitVid);

      //     // Clear height/width from fitVid
      //     fitVid.removeAttribute("height");
      //     fitVid.removeAttribute("width");
      //   }
      // }
  // },
  // NOTE: this code controls lottie button playback on hover
  // mounted() {

  //   this.mouseOverLottie = () => {
  //     const player = document.querySelector("lottie-player")
  //     player.setDirection(1)
  //     player.play();
  //   }

  //   this.mouseLeaveLottie = () => {
  //     const player = document.querySelector("lottie-player")
  //     player.setDirection(-1)
  //     player.play();
  //   }

  //   const lottieButton = document.querySelector(".lottie-button")
  //   lottieButton.addEventListener("mouseover", this.mouseOverLottie)
  //   lottieButton.addEventListener("mouseleave", this.mouseLeaveLottie)
  // },
  // beforeUnmount() {
  //   document.querySelector(".lottie-button").removeEventListener("mouseover", this.mouseOverLottie)
  //   document.querySelector(".lottie-button").removeEventListener("mouseleave", this.mouseLeaveLottie)
  // }
}
</script>

<style scoped>

  /* mousepack color codes */
  /* white   #ffffff */
  /* blue    #1e90ff */
  /* pink    #ff6b81 */
  /* green   #3bd95f */
  /* yellow  #ffc300 */
  
  /* Home Page: Hero Section
  ----------------------------- */
  .cta svg:hover {
    transform: translateY(-20px);
  }
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
  .hero .container {
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    padding: 50px 0;
  }
  .hero .cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    align-items: start;
    /* margin-top: 15px; */
    margin: 0px 50px;
  }
  .hero video {
    position: relative;
    z-index: 12222;
    width: 50%;
    max-width: 720px;
    height: auto;
  }
  .hero video:focus-visible {
    border: none;
    outline: none;
  }
  .hero img {
    position: relative;
    z-index: 11111;
    width: 100%;
  }
  .hero h1 {
    position: relative;
    z-index: 13333;
    margin: 15px 0 5px;
    /* text-align: center; */
    text-align: left;
    font-size: 2.5rem;
    /* line-height: 3.5rem; */
    line-height: 3.0rem;
    /* color: #1e90ff; */
    color: #e7e7e7;
  }
  .hero h1 span {
    display: inline-block;
  }
  .hero h2 {
    margin-bottom: -10px;
    text-align: left;
    color: #1e90ff;
  }
  .hero p {
    width: 90%;
    line-height: 1.421;
    font-size: 1.125rem;
  }
  .night .hero p {
    color: #e7e7e7;
  }
  .hero button {
    padding: 12px 16px;
    border: 3px solid transparent;
    border-radius: 10px;
    margin-top: 25px;
    background: #ff6b81;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    animation: wiggle 10s linear 5s infinite;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s;
  }
  .hero button:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 32px;
  }
  .hero button:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  .hero iframe {
    /* --reelHeight: this.reelHeight; */
    width: 100% !important;
    max-width: 800px;
    height: var(--reelHeight);/* ratio is 1x1 */
    margin-top: 50px;
    /* margin-bottom: 50px; */
  }
  
  /* .fitVids-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.fitVids-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

  /* Keyframes */
  @keyframes wiggle {
    0%, 1% {
      transform: rotateZ(0);
    }
    2% {
      transform: rotateZ(-15deg);
    }
    3% {
      transform: rotateZ(10deg);
    }
    4% {
      transform: rotateZ(-10deg);
    }
    5% {
      transform: rotateZ(6deg);
    }
    6% {
      transform: rotateZ(-4deg);
    }
    7%, 100% {
      transform: rotateZ(0);
    }
  }
  .lottie-button {
    border: 3px solid transparent;
    border-radius: 10px;
    outline: 0;
  }
  .lottie-button:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  

  /* .lottie-button:hover {
    transform: translateY(-10px);
  } */

  .lottie-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s;
  }
  .lottie-button:hover {
    transform: translateY(-3px);
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 32px; */
  }

  /* Reel Modal
  ----------------------------- */
  .reel-modal {
    position: fixed;
    z-index: 22222;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.75);
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  .reel-modal .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 90vw;
    height: 90vh;
  }
  .reel-modal video {
    width: 100%;
    max-width: 750px;
    height: auto;
  }
  .close-modal {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-right: 20px;
    cursor: pointer;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border: 3px solid transparent;
  }
  .icon-container:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 4px;
    outline: 0;
  }
  .close-icon {
    width: 16px;
    height: 16px;
    transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275)
  }
  .close-icon:hover {
    width: 20px;
    height: 20px;
  }
  .reel-modal .container iframe{
    width: 100%;
    height: 100%;
  }
  /* *********************************************** */
  /* START: experimental css for reel button effects */
  /* span.hover {
    position: relative;
    cursor:pointer;
  }
  span.hover::before, span.hover::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 35%;
    top: 0;
    mix-blend-mode: multiply;
    transition: all .3s cubic-bezier(.68,-.25,.265,1.25);
    background: url(../assets/images/image-mask-2.svg) 0 0 no-repeat;
    background-size: auto;
    background-size: 100% 100%;
    transition: all .15s cubic-bezier(.68,-.25,.265,1.25);
  }
  span.hover::before {
    animation: 1.25s shifty linear infinite reverse;
  }
  span.hover::after {
    animation: 1.5s shifty-2 linear infinite;
  }
  @keyframes shifty {
    0% {
      -webkit-filter: drop-shadow(10px 0px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(10px 0px 0 rgba(63, 209, 245,.9));
    }
    16.67% {
      -webkit-filter: drop-shadow(5px 8.66px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(5px 8.66px 0 rgba(63, 209, 245,.9));
    }
    33.33% {
      -webkit-filter: drop-shadow(-5px 8.66px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(-5px 8.66px 0 rgba(63, 209, 245,.9));
    }
    50% {
      -webkit-filter: drop-shadow(-10px 0px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(-10px 0px 0 rgba(63, 209, 245,.9));
    }
    66.67% {
      -webkit-filter: drop-shadow(-5px -8.66px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(-5px -8.66px 0 rgba(63, 209, 245,.9));
    }
    83.33% {
      -webkit-filter: drop-shadow(5px -8.66px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(5px -8.66px 0 rgba(63, 209, 245,.9));
    }
    100% {
      -webkit-filter: drop-shadow(10px 0px 0 rgba(63, 209, 245,.9));
      filter: drop-shadow(10px 0px 0 rgba(63, 209, 245,.9));
    }
  }
  @keyframes shifty-2 {
    0% {
      -webkit-filter: drop-shadow(10px 0px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(10px 0px 0 rgba(255, 195, 0,.9));
    }
    16.67% {
      -webkit-filter: drop-shadow(5px 8.66px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(5px 8.66px 0 rgba(255, 195, 0,.9));
    }
    33.33% {
      -webkit-filter: drop-shadow(-5px 8.66px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(-5px 8.66px 0 rgba(255, 195, 0,.9));
    }
    50% {
      -webkit-filter: drop-shadow(-10px 0px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(-10px 0px 0 rgba(255, 195, 0,.9));
    }
    66.67% {
      -webkit-filter: drop-shadow(-5px -8.66px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(-5px -8.66px 0 rgba(255, 195, 0,.9));
    }
    83.33% {
      -webkit-filter: drop-shadow(5px -8.66px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(5px -8.66px 0 rgba(255, 195, 0,.9));
    }
    100% {
      -webkit-filter: drop-shadow(10px 0px 0 rgba(255, 195, 0,.9));
      filter: drop-shadow(10px 0px 0 rgba(255, 195, 0,.9));
    }
  } */
  /* *********************************************** */
  /* END: experimental css for reel button effects */
  @media screen and (max-width: 1100px) {
    .hero h1 {
      font-size: 2.2rem;
    }
  }
  @media screen and (max-width: 1000px) {
    .hero video {
      width: 45%;
    }
  }
  @media screen and (max-width: 900px) {
    .hero .cta {
      margin: 0px 40px;
    }
  }
  @media screen and (max-width: 800px) {
    .hero .cta {
      margin: 0px 30px;
    }
  }
  @media screen and (max-width: 700px) {
    .hero .container {
      flex-direction: column-reverse;
    }
    .hero .cta {
      align-items: center;
      margin: 0;
    }
    .hero video {
      width: 90%;
    }
    .hero h1 {
      font-size: 2.5rem;
      text-align: center;
      max-width: 500px;
      margin-bottom: 15px;
    }
    .hero p {
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    
  }
  @media screen and (max-width: 500px) {
    .hero h1 {
      /* font-size: 2.5rem; */
      /* line-height: 3rem; */
    }
    .hero h1 span, .first-brands h2 span  {
      display: inline;
    }
  }
  @media screen and (max-width: 400px) {
    .hero h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  @media screen and (max-width: 320px) {
    .hero .container {
      padding: 30px 0 40px;
    }

  }
</style>