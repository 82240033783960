<template>
  <!-- project grid display -->
  <div id="about">
    <div class="container">
      
      <div id="intro">
        <div id="intro-text">
          <h1>About <span>MousePack</span></h1>
          <p>Our creative studio blends the virtual and the real world together seamlessly. We are building a future where you and your friends can have a blast playing together with Augmented Reality.</p>
          <!-- <p>We work towards a future, not where people are isolated by technology, but rather can work, play, and connect organically through AR.</p> -->
        </div>
        <div class="team">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/alex.png" alt="Photo of MousePack owner Alex.">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/davey.png" alt="Photo of MousePack CFO Davey.">
          <!-- <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/gabe.png" alt="Photo of MousePack producer Gabe."> -->
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/melissa.png" alt="Photo of MousePack producer Melissa.">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/steven.png" alt="Photo of MousePack developer Steven Wolpe.">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/krystsina.png" alt="Photo of MousePack 3D artist Krystsina.">
          <img src="https://www.stevenwolpe.com/dev-assets/mpwebsite/images/mike.png" alt="Photo of MousePack developer Mike.">
        </div>
        <!-- <img src="" alt="Picture of Alex Bradt, Owner of MousePack" width="250px" height="250px"> -->
      </div>
      <!-- <div id="experience">
        <p>MousePack has had the amazing opportunity to work with some of the largest tech companies to create highly interactive AR advertisements and experiences. Our portfolio proudly includes Google, Meta, Snapchat, Disney, Sony, ESPN, and many more.</p>
      </div> -->

    </div>
  </div>

  <Process/>
  <Clients/>

</template>

<script>

import Clients from '@/components/layout/Clients'
import Process from '@/components/layout/Process'

export default {
  name: 'About',
  components: {
    Clients,
    Process
  },
  data(){
    return {
    }
  },
  methods: {
    
  },
  computed: {
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style scoped>

  div#about {
    background-color: #e7e7e7;
  }

  div#about #intro {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  }

  div#about #intro #intro-text {
    width: 50%;
    margin: 0 50px;
  }

  div#about #intro #intro-text p {
    color:#121212;
  }

  div#about .team {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 48%;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px; */
  }

  div#about .team img {
    max-width: 125px;
  }
  /* div#about .team img:nth-of-type(3), div#about .team img:last-of-type {
    margin-right: 0;
  } */
  div#about .imgBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 250px; */
    width: 50%;
    height: 350px;
    margin-right: 50px;
    background-color: gray;
    color: white;
    font-size: 2rem;
  }

  div#about #experience {
    margin: 0 50px 50px;
  }

  /* .night #experience {
    background-color: black;
  } */

  div#process {
    padding: 0 15px;
    margin: 50px 0;
    /* max-width: 800px; */
  }

  div#process #process-body {
    margin: 0 0 0 25px;
  }

  div#about h1 {
    font-size: 3rem;
    /* line-height: 3.5rem; */
    line-height: 3.0rem;
    color: #1e90ff;
  }

  div#about h1 span {
    display: block;
    margin: 10px 0 0 -8px;
    font-size: 5rem;
  }

  div#process h2 {
    font-size: 5rem;
    margin: 0;
    color: #1e90ff;
  }

  div#process h3 {
    font-weight: 100;
    font-size: 3rem;
    margin-bottom: 0;
    color: #ff6b81;
  }

  div#process h3 span {
    font-weight: 600;
  }

  div#process h3:first-of-type {
    font-size: 3rem;
    margin-top: 20px;
    /* text-align: left; */
  }

  div#about p {
    /* max-width: 66%; */
    font-size: 1.4rem;
  }
  .night div#about p {
    color: #e7e7e7;
  }
  div#process p {
    /* max-width: 66%; */
    margin-top: 7px;
    font-size: 1.5rem;
    /* margin-left: 50px; */
  }
  
  div#about {
    padding: 0 15px;
    /* margin-bottom: 80px; */
  }
  div#about div.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
  }
  div#process div.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    max-width: 800px;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 1100px) {
    
  }
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 900px) {
    /* div#about .team {
      display: grid;
      grid-template-columns: 1fr 1fr;
    } */
  }
  @media screen and (max-width: 800px) {
    div#about h1 {
      font-size: 2.5rem;
    }
    div#about h1 span {
          margin: -4px 0 0 -6px;
      font-size: 4rem;
    }
  }
  @media screen and (max-width: 700px) {
    div#about #intro {
      flex-direction: column;
      align-items: center;
      justify-content: start;
      width: 90%;
      margin: 50px 0 25px;
    }
    div#about #intro #intro-text {
      width: 100%;
      margin: 0;
    }
    div#about .team {
      max-width: 75%;
      /* grid-template-columns: 1fr 1fr 1fr; */
    }
    div#about .imgBlock {
      width: 100%;
      margin: 25px 0 0;
    }
    div#about #experience {
      width: 90%;
      margin: 0 0 50px;
    }
  }
  @media screen and (max-width: 600px) {
    div#about .team {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    /* div#about .team {
      grid-template-columns: 1fr 1fr;
    } */
  }
  @media screen and (max-width: 400px) {
    div#about #intro {
      width: 100%;
      margin-top:25px
    }
    div#about h1 {
      font-size: 2rem;
    }
    div#about h1 span {
      margin: -10px 0 0 -3px;
      font-size: 3rem;
    }
    div#about p {
      font-size: 1.4rem;;
    }
    div#about #experience {
      width: 100%;
    }
  }
  @media screen and (max-width: 320px) {
    div#about .team img {
      max-width: 110px;
    }
    
  }
  /* mobile browsers with hover support */
  @media only screen and (hover: hover) and (pointer: coarse) {
    
  }
  /* mobile browsers with no hover support */
  @media only screen and (hover: none) and (pointer: coarse) {
    
  }

</style>