<template>
<div class="project modal" v-bind:class="{ active: modalIsCreated }">
  <div class="container" v-bind:class="{ fillavailable: modalIsPlaced }">

    <div class="header-content">
      <div class="project-specs">
        <h1>{{ activeContent.name }}</h1>
        
        <div class="project-summary">
          <!-- <p>{{ activeContent.summary }}</p> -->
          <p v-html="activeContent.summary">
          </p>
          <a v-if="activeContent.linkURL" :href="activeContent.linkURL" rel="noreferrer noopener" target="_blank" class="live-link">{{ activeContent.linkText }}</a>
        </div>
        <!-- <div class="project-header-image">
          TO-DO: make this image a responsive <picture> ??
          <img v-if="!activeContent.videoPreview" width="335" height="251" :src="activeContent.previewImage" :alt="activeContent.altText">

          <video v-else width="335" height="251" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" class="project-image">

            <source :src="activeContent.previewImage" type="video/mp4">
            TO-DO: add poster attr to video tag (Need final asset first!)
            TO-DO: add source(s) for webm and mp4
            TO-DO: add source(s) for smaller screens/mobile devices
            TO-DO: try adding img file for (same as poster) for fallback

          </video>
        </div> -->
        <div class="project-creators">
          
          <div class="project-credits">
            <ul>
              <li v-for="(item, index) in activeContent.credits" :key="index">

                <!-- <span v-if="Array.isArray(item.role)">
                  <h2 v-for="(item, index) in item.role" :key="index">{{ role }}</h2>
                </span> -->
                <h2>{{ item.role }}</h2>

                <span v-if="Array.isArray(item.name)">
                  <h3 v-for="(item, index) in item.name" :key="index">{{ item }}</h3>
                </span>
                <h3 v-else>{{ item.name }}</h3>

              </li>
              <!-- <li><b>Alex Bradt</b><br>
              Creative Direction and Animation
              </li>
              <li>Noah Huelsman <br>
              Sound Design and 3D Modeling
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="video-overlay">
        <video width="335" height="595" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" class="pauseOffScreen">
          <source :src="activeContent.video" type="video/mp4">
          <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
          <!-- TO-DO: add source(s) for webm and mp4 -->
          <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
          <!-- TO-DO: try adding img file for (same as poster) for fallback -->
        </video>
      </div>
    </div>

    <div class="body-content">
      <!-- main description/summary -->
      
      <!-- video trailer here -->
      <div v-if="activeContent.trailer" class="trailer">
        <video width="800" height="450" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" controls="true" :poster="activeContent.poster">
          <source :src="activeContent.trailer" type="video/mp4">
          <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
          <!-- TO-DO: add source(s) for webm and mp4 -->
          <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
          <!-- TO-DO: try adding img file for (same as poster) for fallback -->
        </video>
      </div>
      <!-- begin subsections -->
      <div v-if="activeContent.bts" class="project-breakdown">
        <h4 v-if="activeContent.bts[0].sectionContent[0].subsectionContent">Project Breakdown</h4>
        <div v-for="(section, index) in activeContent.bts" :key="index" class="section">
          <h5>
            {{ section.sectionTitle }}
          </h5>
          <div v-for="(subsection, index) in section.sectionContent" :key="index" class="subsection">
            <div v-if="subsection.subsectionContent" class="subsection-copy">
              <h6>
                {{ subsection.subsectionTitle }}
              </h6>
              <p v-html="subsection.subsectionContent">
              </p>
            </div>
            <!-- // make vfor on mediaURLS, check last 3 chars, then setup img or vid tag -->
            <div class="subsection-media" v-bind:class="{ row: !subsection.subsectionContent }">
              <div v-for="(item, index) in subsection.subsectionMediaUrls" :key="index" class="media-container" v-bind:class="{ halfWidth: !subsection.subsectionContent }">
                <img v-if="item.substring(item.length - 3) === 'jpg' || item.substring(item.length - 3) === 'gif'" width="335" height="251" :src="subsection.subsectionMediaUrls[index]" :alt="activeContent.altText">
                <video v-else width="335" height="595" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" class="pauseOffScreen">
                  <source :src="subsection.subsectionMediaUrls[index]" type="video/mp4">
                  <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
                  <!-- TO-DO: add source(s) for webm and mp4 -->
                  <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
                  <!-- TO-DO: try adding img file for (same as poster) for fallback -->
                </video>
              </div>
            </div>
            <!-- <img v-if="!subsection.videoPreview" width="335" height="251" :src="subsection.subsectionMediaUrls[index]" :alt="activeContent.altText"> -->
            <!-- <video v-else width="335" height="595" autoplay="true" loop="true" muted="true" playsinline="true" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny"> -->
              <!-- <source :src="subsection.subsectionMediaUrls[index]" type="video/mp4"> -->
              <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
              <!-- TO-DO: add source(s) for webm and mp4 -->
              <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
              <!-- TO-DO: try adding img file for (same as poster) for fallback -->
            <!-- </video> -->
          </div>
          
        </div>
        <!-- video trailer here -->
        <div v-if="activeContent.teamVideo" class="trailer">
          <video width="800" height="450" preload="auto" disablepictureinpicture disableremoteplayback x-webkit-airplay="deny" controls="true">
            <source :src="activeContent.teamVideo" type="video/mp4">
            <!-- TO-DO: add poster attr to video tag (Need final asset first!) -->
            <!-- TO-DO: add source(s) for webm and mp4 -->
            <!-- TO-DO: add source(s) for smaller screens/mobile devices -->
            <!-- TO-DO: try adding img file for (same as poster) for fallback -->
          </video>
        </div>
      </div>
    </div>
  </div>

  <div class="close-modal" @keyup.enter="closeModal" @click="closeModal">
    <div class="icon-container" tabindex="0">
      <svg id="close-modal-icon" class="close-icon" data-name="close-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.85 104.85">
        <title>Close Button</title>
        <rect x="355.51" y="155.61" width="20" height="140" rx="10" ry="10" transform="translate(-46.5 -365.56) rotate(45)" fill="#dbdbde"></rect><rect x="355.51" y="155.61" width="20" height="140" rx="10" ry="10" transform="translate(470.41 -46.5) rotate(135)" fill="#dbdbde"></rect>
      </svg>
    </div>
  </div>
</div>
</template>

<script>

import ProjectsData from '../../assets/data/projects.json'

export default {
  name: 'Project',
  data(){
    return {
      origin: this.modalOrigin,
      projectsData: ProjectsData,
      activeContent: this.projectData,
      modalIsCreated: false,
      modalIsPlaced: false,
      handleTab: null
    }
  },
  methods: {
    closeModal() {
      this.modalIsCreated = false
      this.modalIsPlaced = false
      document.removeEventListener('keydown', this.handleTab)
      let origin = this.origin.charAt(0).toUpperCase() + this.origin.slice(1)
      console.log('this is the origin: ', origin)
      if(this.projectData === null){
        // setTimeout(() => {
        this.$router.push({ name: "Cases" })
        // }, 250)
      } else if(origin === "Home"){
        setTimeout(() => {
          this.$router.push({ name: origin })
        }, 250)
      } else if(origin === "Protected"){
        setTimeout(() => {
          this.$router.push({ name: origin })
        }, 250)
      } else {
        setTimeout(() => {
          this.$router.push({ name: "Cases" })
        }, 250)
      }
      
      // setTimeout(() => {
      //   // this.$router.push({ name: origin })
      //   if(origin === "Home"){
      //     this.$router.push({ name: origin }
      //   } 
      // }, 0)
    }
  },
  computed: {
    
  },
  props: {
    projectSlug: String, 
    projectData: Object,
    modalOrigin: String,
  },

  created() {
    // console.log("This is the modal origin: ", this.modalOrigin)
    // console.log("Open this project: ", this.projectId);
    console.log("This is the project data: ", this.projectData);

    this.handleTab = (event) => {
      if(
        // verify event occured in the modal
        event.target.parentElement.classList.contains("body-copy") ||
        event.target.parentElement.classList.contains("close-modal")
      ) {
        console.log("The event occured inside the modal.")
        // TO-DO: verify the currrently focused element is inside the modal 
        // (if last tab was from inside modal, it could have landed outside modal)
      } else {
        console.log("The event occured outside the modal.")
        event.preventDefault();
        // TO-DO: find first focusable element in modal
        document.querySelector(".live-link").focus()
      }
    }

    let body = document.querySelector("body")
    body.classList.add("no-scroll")
    // document.querySelector(".news") // WHAT THE??? whats this doing??
    // body.tabIndex = -1
    setTimeout(()=> { 
      this.modalIsCreated = true
      // now wait .3s to add css for height: -webkit-fill-available
      // keeps page in viewport with out browser ui obstructing it
      // obstruction occurs when touch scrolling "past" top or bottom of page
      setTimeout(() => {
        this.modalIsPlaced = true
        // handle tabing
        // document.querySelector(".live-link").focus()
        // handle tabing
        document.addEventListener('keydown', this.handleTab)
      }, 300)
    }, 1) 
    
    // let modal = document.querySelector("div.modal")
    // modal.classList.add("active")
    if(this.projectData === null){
      // this is a direct url visit, so no projectData prop was passed
      let filteredProjects = this.projectsData.filter((project) => {
        return project.slug === this.projectSlug
      })
      this.activeContent = filteredProjects[0]
    }
  },
  mounted() {
   
    function playPauseVideo() {
      let videos = document.querySelectorAll(".project.modal video.pauseOffScreen");

      let options = {
        root: document.querySelector(".project.modal"),
        rootMargin: "0px",
        threshold: .5
      }

      videos.forEach((video) => {
        video.muted = true;
        let playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            let observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio !== 1 && !video.paused) {
                  video.pause();
                } else if (video.paused) {
                  video.play();
                }
              })
            }, options);
            observer.observe(video)
          })
        }
      })

    }

  playPauseVideo();

  }
}
</script>

<style scoped>
/* Modal 
----------------------------- */
.modal {
	position: fixed;
	right: 0;
	bottom: -100vh;
	left: 0;
  z-index: 33333;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	background: rgba(0, 0, 0, 0.75);
	opacity: 0;
	transition: bottom 0.3s cubic-bezier(.68,-.25,.265,1.25), opacity .3s ease-in-out;
}
.modal.active {
  top: 0;
  bottom: 0;
  opacity: 1;
  overflow: hidden;
}
.modal .container {
  position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
  align-items: center;
	width: 100vw;
	height: calc(100vh - 40px);
	padding: 60px 60px 0;
	margin-top: 40px;
  overflow-y: scroll;
	background: #fff;
}
.modal .container.fillavailable {
  height: -webkit-fill-available;
}
.night .modal .container {
  background: #1e1e1e;
}
.close-modal {
	display: flex;
	align-items: center;
	justify-content: end;
	position: absolute;
	top: 0px;
	right: 0px;
	width: 100%;
	height: 40px;
	cursor: pointer;
  padding-right: 20px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border: 3px solid transparent;
}
.icon-container:focus-visible {
  border: 3px solid #5551ff;
  border-radius: 4px;
  outline: 0;
}
.close-icon {
	width: 16px;
	height: 16px;
  transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.close-icon:hover {
  width: 20px;
  height: 20px;
}
p {
  color: #e7e7e7;
}

.header-content {
	display: flex;
	justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
	max-width: 800px;
	margin: 50px 0 0;
}
.project.modal img, .project.modal video {
  width: 100%;
  height: auto;
  max-width: 800px;
  border-radius: 15px;
  margin-bottom: 30px;
}
.project-specs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 66%;
}
.project-specs h1 {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 3rem;
  font-size: 3rem;
  font-weight: 700;
  color: #1e90ff;
}
.project-credits ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  list-style-type: none;
  font-size: .9rem;
}
.project-credits li {
  margin-right: 15px;
}
.project-credits li:nth-of-type(3), .project-credits li:nth-of-type(6) {
  margin-right: 0;
}
.project-credits h2, h3{
  font-size: .9rem;
}
.project-credits h2 {
  margin-bottom: 0;
}
.night .project-credits h2, .night .project-credits h3 {
  color: #e7e7e7;
}
.project-credits h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}
.project-summary p {
  font-size: 1.75rem;
}
.project-desc {
	font-size: 1.2rem;
	line-height: 1.9rem;
}
.body-content h4 {
  margin-top: 0;
  font-size: 1.75rem;
  color: #1e90ff;
}
.body-copy {
  column-count: 2;
  column-gap: 40px;
  max-width: 800px;
}
.body-copy.oneCol {
  column-count: 1;
  column-gap: 0;
}
.body-copy p {
  margin-top: 0;
  font-size: 1.2rem;
  line-height: 1.875;
}
.body-copy a {
  margin-top: 30px;
}
.header-content .video-overlay {
  display: block;
  width: 33%;
  background-size: cover;
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, .3));
  transition: opacity 0.2s ease;
}
.header-content .video-overlay video {
  width: 100%;
  max-width: 335px;
  border-radius: 15px;
}
.live-link {
  border: 3px solid transparent;
}

.live-link:focus-visible {
  border: 3px solid #5551ff;
  border-radius: 4px;
  outline: 0;
}

/* Project BTS Styling
----------------------------- */
.project-breakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.project-breakdown h4 {
  align-self: flex-start;
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 1.75rem;
  color: #1e90ff;
}
.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.section h5 {
  align-self: flex-start;
  margin-top: 0;
  font-size: 1.5rem;
  color: #ff6b81;
}
.subsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  max-width: 800px;
}
.subsection:nth-child(2n){
  flex-direction: row-reverse;
}
.subsection-media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.subsection-media.row {
  flex-direction: row;
}
.media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.media-container.halfWidth {
  width: 50%;
}
.subsection-copy {
  width: 100%;
  margin-right: 100px;
}
.subsection:nth-child(even) .subsection-copy {
  margin-right: 0;
  margin-left: 100px;
}
.subsection h6 {
  margin-top: 0;
  font-size: 1.25rem;
}
.subsection p {
  margin-top: 0;
  font-size: 1.2rem;
  line-height: 1.875;
}
.night .subsection h6, .night .subsection p {
  color: #e7e7e7;
}
.project.modal .subsection video {
  max-width: 360px;
}
  
@media screen and (max-width: 800px) {
  .project.modal .container {
    padding: 60px 15px;
  }
  .subsection-copy {
    margin-right: 75px;
  }
  .subsection:nth-child(even) .subsection-copy {
    margin-left: 75px;
  }
}
@media screen and (max-width: 700px) {
  .project-specs h1 {
    font-size: 2.75rem;
  }
  .project-summary p {
    max-width: 500px;
    font-size: 1.5rem;
  }
  .subsection-copy {
    margin-right: 50px;
  }
  .subsection:nth-child(even) .subsection-copy {
    margin-left: 50px;
  }
}
@media screen and (max-width: 600px) {
  .header-content {
    flex-direction: column-reverse;
    gap: 0;
    margin: 0;
  }
  .header-content .video-overlay {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .project.modal img, .project.modal video {
    height: auto;
    margin-bottom: 20px;
  }
  .header-content .project-specs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .project-specs h1 {
    margin-bottom: 0;
  }
  .project-credits {
    margin-bottom: 30px;
  }
  .body-copy {
    column-count: 1;
  }
  .subsection-copy {
    margin-right: 25px;
  }
  .subsection:nth-child(even) .subsection-copy {
    margin-left: 25px;
  }
}
@media screen and (max-width: 500px) {
  .project.modal img {
    max-width: 360px;
  }
  .subsection, .subsection:nth-child(2n){
    flex-direction: column;
  }
  .subsection-copy {
    margin-right: 0px;
  }
  .subsection:nth-child(even) .subsection-copy {
    margin-left: 0px;
  }
  .section h5, .subsection h6 {
    margin-bottom: 1rem;
  }
  .subsection-media.row {
    flex-direction: column;
  }
  .media-container.halfWidth {
    width: 100%
  }
}
@media screen and (max-width: 400px) {
  .header-content {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .project-specs h1 {
    font-size: 2.25rem;
  }
}
@media screen and (max-width: 320px) {
  .project-specs h1 {
    font-size: 2rem;
  }
}
</style>

 

