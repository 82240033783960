<template>
<div>

  <div id="newsletter-preview">
    <div class="container">
      <button>Read Our Newsletter</button>
    </div>
  </div>
  
 
</div>
  
</template>

<script>
export default {
  name: 'NewsLetterPreview',
  data(){
    return {
      
    }
  },
  methods: {
   
  },
  created() {

  }
}
</script>

<style scoped>
  #newsletter-preview {
    /* background: linear-gradient(to top, #f3f3f4, #e7e7e7); */
    padding: 0 15px;
  }
  #newsletter-preview .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    /* column-gap: 55px; */
    /* max-width: 1000px; */
    height: auto;
    /* max-height: 100px; */
    padding: 0 0 40px;
    /* margin-bottom: 40px; */
  }
  #newsletter-preview button {
    padding: 12px 16px;
    border: 3px solid transparent;
    border-radius: 10px;
    margin-top: 25px;
    background: #ff6b81;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    animation: wiggle 10s linear 5s infinite;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s;
  }
  #newsletter-preview button:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 32px;
  }
  #newsletter-preview button:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  .first-brands h2 {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: #1e90ff;
  }
  .first-brands h2 span {
    display: inline-block;
  }
</style>