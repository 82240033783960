<template>
<div>

  <div id="newsletter-signup">
    <div class="container">
      <a href="https://mailchi.mp/299c6db5d96a/the-latest-with-mousepack" target="_blank" rel="noopener noreferrer">
        <button class="previewBtn" >Read Our Newsletter</button>
      </a>
      <h3>Subscribe for quarterly news and updates!</h3>
      <div class="form">
        <input type="text" placeholder="Email"><button class="signup">Sign Up</button>
      </div>
      
    </div>
  </div>
  
 
</div>
  
</template>

<script>
export default {
  name: 'NewsLetterSignup',
  data(){
    return {
      
    }
  },
  methods: {
   
  },
  created() {

  }
}
</script>

<style scoped>
  #newsletter-signup {
    /* background: #1e90ff; */
    background: linear-gradient(to top, #f3f3f4, #e7e7e7);
    padding: 40px 15px 0;
  }
  .night #newsletter-signup {
    /* background: #1e90ff; */
    background: linear-gradient(0deg,#114c86,#1e90ff);
  }
  #newsletter-signup .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    padding: 0 0 40px;
  }
  #newsletter-signup h3 {
    margin: 30px 0 15px;
    text-align: center;
  }
  .night #newsletter-signup h3 {
    color: #e7e7e7;
  }
  #newsletter-signup .form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #newsletter-signup input {
    height: 30px;
    margin-right: 20px;
    padding-left: 10px;
    font-size: 1.25rem;
  }
  #newsletter-signup button {
    height: auto;
    padding: 8px 12px;
    border: 3px solid transparent;
    border-radius: 6px;
    /* margin-top: 25px; */
    background: #ff6b81;
    font-family: inherit;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    animation: wiggle 10s linear 5s infinite;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s;
  }
  #newsletter-signup button.previewBtn {
    padding: 12px 16px;
    border-radius: 10px;
    font-size: 1.5rem;
  }
  #newsletter-signup button:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 32px;
  }
  #newsletter-signup button:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 10px;
    outline: 0;
  }
  .first-brands h2 {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: #1e90ff;
  }
  .first-brands h2 span {
    display: inline-block;
  }
  @media screen and (max-width: 500px) {
    #newsletter-signup h3 {
      margin-top: 60px;
    }
    #newsletter-signup .form {
      flex-direction: column;
    }
    #newsletter-signup input {
      margin: 0 0 20px 0;
    }
    #newsletter-signup button.signup {
      width: 100%;
    }
  }
  @media screen and (max-width: 400px) {
    #newsletter-signup {
      padding: 40px 30px 0;
    }
  }
  @media screen and (max-width: 320px) {
    #newsletter-signup {
      padding: 40px 10px 0;
    }
  }
  
</style>