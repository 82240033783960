<template>
  <div class="ig-feed-container">
    <h3>MousePack Feed</h3>
    <!-- <iframe id="igFeed" src="https://widget.tagembed.com/80110?view" frameborder="0" allowtransparency="true"></iframe> -->
    <!-- <iframe src='https://www.juicer.io/api/feeds/mousepack-co/iframe' frameborder='0' width='1000' height='700' style='display:block;margin:0 auto;' data-overlay="true"></iframe>-->
    <!-- <script type="application/javascript" src="https://www.juicer.io/embed/mousepack-co/embed-code.js" async defer></script> -->
    <component is="script" src="https://www.juicer.io/embed/mousepack-co/embed-code.js" async defer></component>
  </div>
</template>

<script>
export default {
  name: 'InstagramFeed',
  data(){
    return {
      reelHeight: 700
    }
  },
  methods: {

  },
  computed: {
    
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style scoped>
  .ig-feed-container {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* min-height: 500px; */
    /* padding: 0 15px; */
    max-width: 1200px;
    margin: 0 auto;
  }
  iframe {
    /* width: 100% !important; */
    /* max-width: 1100px; */
    /* height:700px; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* margin-bottom: 50px; */
    overflow: hidden;
    
  }
  .ig-feed-container h3 {
    /* margin-top: 0; */
    /* margin-bottom: 60px; */
    text-align: center;
    font-size: 2.125rem;
    color: #fff;
    margin: 0 auto 0px;
    background: #1e8ffe;
    max-width: 1200px;
    margin-bottom: -20px;
    padding-bottom: 12px;
    padding-top: 2px;
  }

  /* @media screen and (max-width: 800px) {
    .ig-feed-container {
      padding: 0;
    }
  }
  @media screen and (max-width: 750px) {
    iframe {
      height:580px;
    }
  } */
  
</style>