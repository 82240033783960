<template>
  <header>
    <div class="container">
      <div class="logo">
        <router-link to="/" tabindex="1">
          <svg title="Go To MousePack Home Page" id="mousepack-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <defs></defs><path id="circle" class="cls-1" d="M49.39.11A50,50,0,1,1,0,51.05,50,50,0,0,1,49.39.11Z" transform="translate(0 -0.1)"/><g id="mouse-shapes"><path class="cls-2" d="M85.51,47c0,7.79-4.73,13.87-11.45,15.5a13.69,13.69,0,0,1-5.1.27,1.67,1.67,0,0,1-.54-.2q-10.79-6-21.55-12a3,3,0,0,1-1.24-4.33q3.65-6.6,7.31-13.19c.12-.22.21-.42.55-.39a15.35,15.35,0,0,1,6.78,2.11l.4.27c.31.22.63.63.94.63s.63-.42,1-.64a14.5,14.5,0,0,1,21.28,5.6A14.73,14.73,0,0,1,85.51,47Z" transform="translate(0 -0.1)"/><path class="cls-2" d="M48.49,73.1a25.75,25.75,0,0,1-8.89-1.38,13.16,13.16,0,0,1-5.35-3.53.7.7,0,0,1-.12-1c2.15-3.82,4.27-7.66,6.4-11.5A3,3,0,0,1,45,54.45L66.1,66.15c.49.28.49.28.12.69a17.89,17.89,0,0,1-8.34,5A33.61,33.61,0,0,1,48.49,73.1Z" transform="translate(0 -0.1)"/><path class="cls-2" d="M28.7,32.39a14.51,14.51,0,0,1,10.05,3.7c.3.27.46.28.76,0A12.41,12.41,0,0,1,46,33c.66-.13,1.34-.2,2-.3.25,0,.39,0,.21.28s-.27.47-.4.7q-4,7.19-8,14.37a3,3,0,0,1-4.48,1.28L17.45,39.37c-.36-.2-.43-.34-.17-.7A14.31,14.31,0,0,1,28.7,32.39Z" transform="translate(0 -0.1)"/><path class="cls-2" d="M15,51.71a15.71,15.71,0,0,1,0-8.14c.13-.49.14-.49.58-.24l17.65,9.85a3,3,0,0,1,1.25,4.3c-.88,1.59-1.78,3.17-2.65,4.76a.73.73,0,0,1-.62.42C23.25,63.9,16.77,58.23,15,51.71Z" transform="translate(0 -0.1)"/></g>
          </svg>
        </router-link>
      </div>
      <div class="main-navigation">
        <ul>
          <li><router-link to="/about" tabindex="2">About</router-link></li>
          <li><router-link to="/work" tabindex="2">AR</router-link></li>
          <li><a href="https://motion.mousepack.com" tabindex="2" target="_blank" rel="noopener noreferrer">Motion</a></li>
          <li><router-link to="/cases" tabindex="2">Cases</router-link></li>
          <li><a href="mailto:alex@mousepack.com" tabindex="2" class="btn">Contact</a></li>
          <!-- <li><router-link to="/news" tabindex="3">News</router-link></li> -->
          <!-- TO-DO: add router link here -->
          <!-- <li><a href="#" tabindex="4">About</a></li> -->
        </ul>
      </div>
      <div @click="openSideNavigation" class="side-navigation-button">
        <svg id="Menu_Button" data-name="Menu Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 48"><g id="Bottom_Bar" data-name="Bottom Bar">
          <title>Open Menu</title>
          <rect width="60" height="8" rx="4"/></g><g id="Middle_Bar" data-name="Middle Bar"><rect y="20" width="60" height="8" rx="4"/></g><g id="Top_Bar" data-name="Top Bar"><rect y="40" width="60" height="8" rx="4"/></g></svg>
      </div>
    </div>
    <div @click="closeSideNavigation" class="side-navigation-overlay">
      <div class="side-navigation">
        <div @click="closeSideNavigation" class="close-side-navigation">
          <svg id="close-side-navigation-icon" class="close-icon" data-name="close-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.85 104.85">
            <title>Close Menu</title>
            <rect x="355.51" y="155.61" width="20" height="140" rx="10" ry="10" transform="translate(-46.5 -365.56) rotate(45)" fill="#dbdbde"></rect><rect x="355.51" y="155.61" width="20" height="140" rx="10" ry="10" transform="translate(470.41 -46.5) rotate(135)" fill="#dbdbde"></rect>
          </svg>
        </div>
        <ul>
          <li @click="closeSideNavigation"><router-link to="/">Home</router-link></li>
          <li @click="closeSideNavigation"><router-link to="/about">About</router-link></li>
          <li @click="closeSideNavigation"><router-link to="/work">AR</router-link></li>
          <li @click="closeSideNavigation"><a href="https://motion.mousepack.com" target="_blank" rel="noopener noreferrer">Motion</a></li>
          <li @click="closeSideNavigation"><router-link to="/cases">Cases</router-link></li>
          <li><a href="mailto:alex@mousepack.com">Contact</a></li>
          <!-- <li @click="closeSideNavigation"><router-link to="/news">News</router-link></li> -->
          <!-- TO-DO: add router link here -->
          <!-- <li @click="closeSideNavigation"><a href="#">About</a></li> -->
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  data(){
    return {
    }
  },
  methods: {
    openSideNavigation() {
      // add class visible to side-navigation (initially hidden for screen readers, etc)
      // use setimeout for a delay (needs to be visible before changing position)
      // then add class active to side-navigation (changes left position)
      let overlay = document.querySelector(".side-navigation-overlay")
      let sideNav = document.querySelector(".side-navigation")
      overlay.classList.add("visible")
      sideNav.classList.add("visible")
      setTimeout(() => {
        overlay.classList.add("active")
        sideNav.classList.add("active")
        document.querySelector("body").style.overflow = "hidden"
        }, 100)
    },
    closeSideNavigation() {
      let overlay = document.querySelector(".side-navigation-overlay")
      let sideNav = document.querySelector(".side-navigation")
      sideNav.classList.remove("active")
      overlay.classList.remove("active")
      setTimeout(() => {
        sideNav.classList.remove("visible")
        overlay.classList.remove("visible")
        document.querySelector("body").style.overflow = "visible"
        }, 200)

    }
  },
  created() {

  }
}
</script>

<style>
  /* Header Component 
  ----------------------------- */
  header {
    height: 80px;
    margin-bottom: 0;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  }
  header .container, header .logo {
    display: flex;
    align-items: center;
  }
  header .container {
    /* display: flex; */
    justify-content: space-between;
    /* align-items: center; */
    padding: 0 40px;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  header .logo {
    /* display: flex; */
    justify-content: center;
    /* align-items: center; */
    height: 60px;
  }
  header .logo a {
    display: block;
    height: 100%;
    border: 3px solid transparent;
  }
  header .logo a:focus {
    border: 3px solid #5551ff;
    border-radius: 50%;
    outline: 0;
  }
  header .logo svg {
    height: 100%;
  }
  header .logo svg .cls-1 {
    fill:#1e8ffe;
  }
  header .logo svg .cls-2 {
    fill:#fefefe;
  }
  header .main-navigation ul {
    list-style: none;
  }
  header .main-navigation ul li {
    display: inline;
    box-sizing: border-box;
    margin-right: 20px;
  }
  header .main-navigation ul li:last-of-type {
    margin-right: 0px;
  }
  header .main-navigation a {
    padding: 1px;
    border: 3px solid transparent;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    color: #1e90ff;
    transition: all .2s ease;
  }
  header .main-navigation a.btn {
    padding: 6px;
    border: 3px solid transparent;
    border-radius: 10px;
    margin-top: 25px;
    background: #ff6b81;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    -webkit-animation: wiggle-fae5bece 10s linear 5s infinite;
    animation: wiggle-fae5bece 10s linear 5s infinite;
    transition: transform 0.2s ease 0s, background 0.2s ease 0s;
  }
  .night header .main-navigation a {
    color: #e7e7e7;
  }
  header .main-navigation a:focus-visible {
    border: 3px solid #5551ff;
    border-radius: 4px;
    outline: 0;
  }
  header .main-navigation a.router-link-exact-active {
    color: #ff6b81;
  }
  header .main-navigation a:hover {
    color: #ff6b81;
  }
  header .main-navigation a.btn:hover {
    color: #ffffff;
  }
  .night header .main-navigation a.router-link-exact-active {
    color: #1e90ff;
    font-size: 1.9rem;
  }
  .side-navigation-button {
    display: none;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
  .side-navigation-button svg {
    height: 30px;
    width: 30px;
    fill: #1e90ff;
  }
  .side-navigation-button svg:hover {
    fill: #ff6b81;
    transition: all .2s ease-in-out;
  }
  .side-navigation-button svg rect {
    fill: inherit;
  }
  .side-navigation {
    position: fixed;
    top: 0;
    left: -250px;
    display: none;
    z-index: 88888;
    height: 120vh;
    width: 250px;
    background: #1e90ff;
    transition: left 0.2s cubic-bezier(0.7, -0.5, 0.265, 1.5);
  }
  .side-navigation.visible{
    display: block;
  }
  .side-navigation.active {
    left: 0;
  }
  .side-navigation-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 55555;
    height: 100vh;
    opacity: 0;
    background: rgba(0,0,0,.66);
    transition: opacity .2s ease-in-out;
  }
  .side-navigation-overlay.visible {
    display: block;
  }
  .side-navigation-overlay.active {
    opacity: 1;
  }
  .close-side-navigation {
    position: absolute;
    top: 0;
    right: 0;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .side-navigation ul {
    padding-inline-start: 0;
    margin-top: 60px;
    list-style: none;
  }
  .side-navigation li {
    height: 100px;
    text-align: center;
  }
  .side-navigation a {
    display: inline-block;
    padding: 1.5rem 0;
    width: 100%;
    text-decoration: none;
    font-size: 2rem;
    color:#ffffff;
    transition: all 0.2s cubic-bezier(0.7, -0.5, 0.265, 1.5);
  }
  .side-navigation a:hover {
    font-size: 2.5rem;
  }
  .close-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275)
  }
  .close-icon:hover {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 600px) {
    .main-navigation {
      display: none;
    }
    .side-navigation-button {
      display: flex;
    }
  }
  @media screen and (max-width: 500px) {
    header .container {
      padding: 0 15px;
    }
  }
  /* @media screen and (max-width: 400px) {
    .main-navigation {
      display: none;
    }
    .side-navigation-button {
      display: flex;
    }
  } */
</style>